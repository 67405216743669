export const parserFunctions = {
  unitPrice: (cellStr) => {
    const parsedData = cellStr.match(/([0-9]+([.][0-9]*)?|[.][0-9]+)/gi)?.[0];
    if (parsedData) {
      return parsedData;
    } else {
      throw new Error('Parsing error at BomParser!');
    }
  }
}
