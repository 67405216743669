import React from "react";
import { Route } from "react-router-dom";
import dataProvider from "./data_provider";
import { Admin, Resource } from "react-admin";
import { authProvider } from "./auth_provider";
import Layout from "./components/Layout/Index";
import { Login } from "./components/Login/Index";
import { Signup } from "./components/Signup/Index";
import { theme } from "./components/Layout/theme";
import PricingResource from "./components/Pricing";
import Dashboard from "./components/Dashboard/Index";
import StaffResource from "./components/Staff/Index";
import MindBodyResource from "./components/MindBody/index";
import LeadsResource from "./components/Leads/Index";
import OwnersResource from "./components/Owners/Index";
import ClientsResource from "./components/Clients/Index";
import LocationsResource from "./components/Locations/Index";
import DocumentsResource from "./components/Documents/Index";
import AdminProfileResource from "./components/Admin/Index";
import GroupChatResource from "./components/GroupChat/Index";
import { ResetPassword } from "./components/Login/ResetPass";
import { ForgotPassword } from "./components/Login/ForgotPass";
import DirectChatResource from "./components/DirectChat/Index";
import DocManagementResource from "./components/DocManagement/Index";
import {
  MemberShipResource,
  CustomerResource,
} from "./components/Reporting/Index";

const App = () => {
  return (
    <Admin
      title=""
      dashboard={Dashboard}
      authProvider={authProvider}
      loginPage={Login}
      theme={theme}
      dataProvider={dataProvider}
      layout={Layout}
      customRoutes={[
        <Route
          key="my-profile"
          path="/my-profile"
          component={AdminProfileResource.MyProfile}
        />,
        <Route
          key="pricing"
          path="/pricing"
          component={PricingResource.Plans}
        />,
        <Route exact path="/signup" component={Signup} noLayout />,
        <Route
          exact
          path="/user/forgotpassword"
          component={ForgotPassword}
          noLayout
        />,
        <Route
          exact
          path="/resetpassword/:id"
          component={ResetPassword}
          noLayout
        />,
      ]}
    >
      <Resource
        name="profile"
        edit={AdminProfileResource.Edit}
        create={AdminProfileResource.Create}
      />
      <Resource
        name="customerReporting"
        list={CustomerResource.List}
        edit={CustomerResource.Edit}
      />
      <Resource
        name="memberShipReporting"
        list={MemberShipResource.List}
        edit={MemberShipResource.Edit}
      />
      <Resource
        name="clients"
        list={ClientsResource.List}
        edit={ClientsResource.Edit}
      />
      <Resource
        name="owners"
        list={OwnersResource.List}
        edit={OwnersResource.Edit}
      />
      <Resource
        name="staff"
        list={StaffResource.List}
        edit={StaffResource.Edit}
      />
      <Resource
        name="location"
        list={LocationsResource.List}
        edit={LocationsResource.Edit}
      />
      <Resource
        name="document"
        list={DocumentsResource.List}
        edit={DocumentsResource.Edit}
        show={DocumentsResource.Show}
      />
      <Resource
        name="documentTypeManagement"
        list={DocManagementResource.List}
        edit={DocManagementResource.Edit}
      />
      <Resource
        name="leads"
        list={LeadsResource.List}
        edit={LeadsResource.Edit}
      />

      <Resource
        name="mindbody"
        list={MindBodyResource.List}
        edit={MindBodyResource.Edit}
      />

      <Resource name="owners" list={OwnersResource.List} />
      <Resource name="messages" list={DirectChatResource.List} />
      <Resource name="groupChats" list={GroupChatResource.List} />
      <Resource name="groupChat" create={GroupChatResource.Create} />
    </Admin>
  );
};

export default App;
