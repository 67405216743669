import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { stringify } from "query-string";
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL + "/user",
  fetchJson
);

export const myDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    if (resource === "location") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/${resource}/getAllLocations?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else if (resource === "leads") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/user/leadRequest?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else if (resource === "mindbody") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/${resource}/getAllStaffs?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else if (resource === "document") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/${resource}/getAllDocuments?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else if (resource === "documentTypeManagement") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/${resource}/getAllDocumentsType?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else if (resource === "customerReporting") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/report/getReport?${stringify({
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        reporting_type: "customer",
      })}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else if (resource === "memberShipReporting") {
      const url = `${
        process.env.REACT_APP_API_URL
      }/report/getReport?${stringify({
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        reporting_type: "member",
      })}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      }));
    } else {
      return dataProvider.getList(resource, params);
    }
  },

  getOne: (resource, params) => {
    if (resource === "location") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/getLocation/${params.id}`;

      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "leads") {
      const url = `${process.env.REACT_APP_API_URL}/user/leadRequest/${params.id}`;

      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "mindbody") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/staff/${params.id}`;

      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "document") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/getDocument/${params.id}`;

      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "documentTypeManagement") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`;

      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    } else if (
      resource === "customerReporting" ||
      resource === "memberShipReporting"
    ) {
      const url = `${process.env.REACT_APP_API_URL}/report/getReport/${params.id}`;

      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    }
    return dataProvider.getOne(resource, params);
  },

  update: (resource, params) => {
    if (resource === "document") {
      let formData = new FormData();

      const isDocumentArray = Array.isArray(params.data.url);
      if (isDocumentArray) {
        for (let elem of params.data.url) {
          if (elem.rawFile === undefined) {
            formData.append("url[]", JSON.stringify(elem));
          } else if (elem.rawFile !== undefined) {
            formData.append("documents[]", elem.rawFile);
          }
        }
      } else {
        if (params.data.document.rawFile) {
          formData.append("documents", params.data.document.rawFile);
        }
      }
      formData.append("name", params.data.name);
      formData.append("doc_type", params.data.doc_type);

      for (let elem of params.data.locations) {
        formData.append("locations[]", elem);
      }
      for (let elem of params.data.access_level) {
        formData.append("access_level[]", elem);
      }

      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/getDocument/${params.id}`,
        {
          method: "PUT",
          body: formData,
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "leads") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/user/leadRequest/${params.id}`,
        {
          method: "PUT",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "mindbody") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/staff/${params.id}`,
        {
          method: "PUT",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "location") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/getLocation/${params.id}`,
        {
          method: "PUT",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "documentTypeManagement") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
        {
          method: "PUT",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (
      resource === "customerReporting" ||
      resource === "memberShipReporting"
    ) {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/report/getReport/${params.id}`,
        {
          method: "PUT",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else {
      return dataProvider.update(resource, params);
    }
  },
  create: (resource, params) => {
    if (resource === "document") {
      let formData = new FormData();

      const isDocumentArray = Array.isArray(params.data.document);
      if (isDocumentArray) {
        for (let elem of params.data.document) {
          formData.append("documents", elem.rawFile);
        }
      } else {
        formData.append("documents", params.data.document.rawFile);
      }
      formData.append("name", params.data.name);
      formData.append("doc_type", params.data.doc_type);

      for (let elem of params.data.locations) {
        formData.append("locations[]", elem);
      }
      for (let elem of params.data.access_level) {
        formData.append("access_level[]", elem);
      }

      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/newDocuments`,
        {
          method: "POST",
          body: formData,
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "location") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/newLocation`,
        {
          method: "POST",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "documentTypeManagement") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/newDocumentType`,
        {
          method: "POST",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "groupChats") {
      if (params.data.group_id) {
        return httpClient(`${process.env.REACT_APP_API_URL}/group/addMember`, {
          method: "POST",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      } else {
        let formData = new FormData();
        if (params.data.image && params.data.image.rawFile) {
          formData.append("image", params.data.image.rawFile);
        }
        formData.append("title", params.data.title);
        formData.append("description", params.data.description);
        formData.append("access_level", params.data.access_level);
        if (!!params.data.locations) {
          formData.append("locations", params.data.locations);
        }
        return httpClient(
          `${process.env.REACT_APP_API_URL}/group/createGroup`,
          {
            method: "POST",
            body: formData,
          }
        ).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      }
    } else if (resource === "mindbody") {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/mindbody/connectToMindbody`,
        {
          method: "POST",
          body: JSON.stringify(params.data),
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else {
      return dataProvider.create(resource, params);
    }
  },

  delete: (resource, params) => {
    if (resource === "location") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/getLocation/${params.id}`;

      return httpClient(url, { method: "DELETE" }).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "document") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/getDocument/${params.id}`;

      return httpClient(url, { method: "DELETE" }).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "mindbody") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/staff/${params.id}`;

      return httpClient(url, { method: "DELETE" }).then(({ json }) => ({
        data: json,
      }));
    } else if (resource === "documentTypeManagement") {
      const url = `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`;

      return httpClient(url, { method: "DELETE" }).then(({ json }) => ({
        data: json,
      }));
    } else if (
      resource === "customerReporting" ||
      resource === "memberShipReporting"
    ) {
      const url = `${process.env.REACT_APP_API_URL}/report/getReport/${params.id}`;

      return httpClient(url, { method: "DELETE" }).then(({ json }) => ({
        data: json,
      }));
    } else {
      return dataProvider.delete(resource, params);
    }
  },
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth");
  if (!options.isMindBodyLogin) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

export default myDataProvider;
