import { useState } from "react";
import { useRedirect } from "react-admin";
import { createStyles, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: "#7CBA5F",
      padding: "6px 14px",
      borderRadius: "6px",
      fontSize: "14px",
      background: "#F0FFE9",
      display: "inline-block",
    },
    blocked: {
      color: "#E66161",
      padding: "6px 14px",
      borderRadius: "6px",
      fontSize: "14px",
      background: "#FFE9E9",
      display: "inline-block",
    },
    pending: {
      color: "#a6a112",
      padding: "6px 14px",
      borderRadius: "6px",
      fontSize: "14px",
      background: "#f2eb1d",
      display: "inline-block",
    },
  })
);

export const ActivityList = ({ record, resource }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [open, setOpen] = useState(false);

  const toggleClick = (state = !open) => {
    setOpen(state);
  };

  return [
    (record.status === "Active" || record.status === "Approved") && (
      <Typography
        variant="body1"
        className={classes.active}
        onClick={() => redirect(`/${resource}/${record.id}`)}
      >
        Active
      </Typography>
    ),

    (record.status === "Block" || record.status === "Rejected") && (
      <Typography
        variant="body1"
        className={classes.blocked}
        onClick={() => toggleClick(true)}
      >
        Blocked
      </Typography>
    ),

    record.status === "Pending" && (
      <Typography
        variant="body1"
        className={classes.pending}
        onClick={() => toggleClick(true)}
      >
        Pending
      </Typography>
    ),
  ];
};
