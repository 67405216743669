import React, { useState, useContext } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { IoMdCloseCircle } from "react-icons/io";
import MessageContext from "../../../context/MessageContext";

const MsgInput = ({ docsIcon, selectedUserId }) => {
  const token = localStorage.getItem("auth");
  const msgCreatorId = localStorage.getItem("id");
  const userName = localStorage.getItem("full_name");
  const profileImg = localStorage.getItem("profile_image");
  const [typedMsg, setTypedMsg] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [messageuuId, setMessageuuId] = useState(uuidv4());
  const { getAllMessages } = useContext(MessageContext);

  const sendMessageHandler = (e) => {
    e.preventDefault();
    setMessageuuId(uuidv4);
    if (typedMsg !== "" || uploadFiles.length !== 0) {
      let formData = new FormData();

      formData.append("text", typedMsg);
      formData.append("for_group", false);
      formData.append("to", selectedUserId);
      formData.append("message_id", messageuuId);

      const uploadedImages = [];
      const uploadedDocs = [];
      for (let focusedElem of uploadFiles) {
        formData.append("filesToBeUploaded", focusedElem.orgObject);
        if (
          focusedElem.type === "image/png" ||
          focusedElem.type === "image/jpg" ||
          focusedElem.type === "image/jpeg" ||
          focusedElem.type === "image/heic" ||
          focusedElem.type === "image/heif"
        ) {
          uploadedImages.push(focusedElem);
        } else {
          uploadedDocs.push(focusedElem);
        }
      }

      getAllMessages((current) => [
        ...current,
        {
          createdAt: moment().format("MM/DD/YYYY"),
          from: msgCreatorId,
          user_name: userName,
          text: typedMsg,
          message_id: messageuuId,
          message_date: new Date(),
          image_url: profileImg,
          images: uploadedImages,
          documents: uploadedDocs,
        },
      ]);
      setTypedMsg("");
      setUploadFiles([]);

      fetch(`${process.env.REACT_APP_API_URL}/messages/sendMessage`, {
        method: "POST",
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });
    }
  };

  const handleFileUpload = (event) => {
    const previewImgs = Array.from(event.target.files);
    const previewImgsMapped = previewImgs.map((focusedImg) => {
      return {
        src: URL.createObjectURL(focusedImg),
        orgObject: focusedImg,
        type: focusedImg.type,
        name: focusedImg.name,
      };
    });
    setUploadFiles((previous) => [...previous, ...previewImgsMapped]);
    event.target.value = null;
  };

  const handleRemoveImage = (elem) => {
    setUploadFiles((prevState) => {
      return prevState.filter((data) => {
        return data.src !== elem.src;
      });
    });
  };

  return (
    <div className="channel__input">
      <form onSubmit={sendMessageHandler}>
        <label>
          <input
            multiple
            type="file"
            className="attach-icon"
            onChange={(event) => handleFileUpload(event)}
            accept="image/png, image/jpeg, image/jpg, image/heic, image/heif, .xlsx, .csv, .pdf, .docx"
          />
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5.33325L5.66666 10.6666C4.93028 11.403 4.93028 12.5969 5.66666 13.3333C6.40304 14.0696 7.59695 14.0696 8.33333 13.3333L13.6667 7.99992C15.1394 6.52716 15.1394 4.13934 13.6667 2.66658C12.1939 1.19382 9.80609 1.19382 8.33333 2.66658L2.99999 7.99992C0.790854 10.2091 0.790856 13.7908 3 15.9999C5.20914 18.2091 8.79086 18.2091 11 15.9999L16.3333 10.6666"
              stroke="#09102A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </label>

        <div className="text-field">
          <input
            type="text"
            value={typedMsg}
            placeholder="Write something here...."
            onChange={(e) => setTypedMsg(e.target.value)}
          />

          <div className="uploaded-wrapper">
            {uploadFiles.map((elem, index) => {
              return (
                <div key={index}>
                  <i
                    className="close-btn"
                    onClick={() => handleRemoveImage(elem)}
                  >
                    <IoMdCloseCircle />
                  </i>

                  {elem.type === "text/csv" ||
                  elem.type === "application/pdf" ||
                  elem.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  elem.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                    <div className="uploaded-documents">
                      <i>{docsIcon(elem.type)}</i>
                      <label>{elem.name}</label>
                    </div>
                  ) : (
                    <img src={elem.src} alt="" />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <button type="submit">
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 9.99999H11M1 7.99999H3M1 12H3M8.45352 2.26033L19.6842 7.87225C21.4386 8.74893 21.4386 11.2511 19.6842 12.1278L8.45352 17.7397C6.45607 18.7378 4.30682 16.6853 5.21382 14.6458L6.85018 10.9662C7.12373 10.3511 7.12373 9.64894 6.85018 9.03384L5.21382 5.35425C4.30682 3.31474 6.45607 1.26221 8.45352 2.26033Z"
              stroke="#09102A"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default MsgInput;
