import { BasicDetails } from "./BasicDetails";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";
import { Edit, useAuthenticated, FormWithRedirect } from "react-admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

export const OwnersEdit = (props) => {
  useAuthenticated();
  const classes = useStyles();
  const tabsWithDetails = {
    basic_details: {
      name: "Edit Vendor Details",
      component: <BasicDetails />,
    },
  };
  const tabIds = Object.keys(tabsWithDetails);

  return (
    <Edit
      className={classes.root}
      title="Vendor Details"
      mutationMode="pessimistic"
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={(formProps) => [
          <Container key="content">
            {tabsWithDetails[tabIds[0]].component}
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
