import React, { useState, useEffect, useContext } from "react";
import { useNotify, useRefresh, useAuthenticated, List } from "react-admin";
import moment from "moment";
import { useSelector } from "react-redux";
import { groupBy, mapValues, sortBy } from "lodash";
import Loader from "../../../General/views/Loader";
import MessageContext from "../../../../context/MessageContext";
import {
  Pie,
  Cell,
  Area,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  PieChart,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import {
  getOriginalCsvData,
  getParsedFileData,
} from "../../../General/utils/FileUploadGeneral";
import sampleIcon from "../../../../assets/images/sampleIcon.png";
import importIcon from "../../../../assets/images/importIcon.png";
import exportPngIcon from "../../../../assets/images/exportPngIcon.png";
import starIcon from "../../../../assets/images/starIcon.png";
import frozenIcon from "../../../../assets/images/frozenIcon.png";
import payfailureIcon from "../../../../assets/images/payfailureIcon.png";

export const MemberShip = (props) => {
  useAuthenticated();
  const notify = useNotify();
  const refresh = useRefresh();
  const token = localStorage.getItem("auth");
  const COLORS = ["#FF5AA9", "#FFAD4E", "#9BE94E", "#8951FF"];
  const { loader, setLoader } = useContext(MessageContext);
  const [graphData, setGraphData] = useState([]);
  const [activeTab, setActiveTab] = useState("active");
  const [lineChartData, setLineChartData] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    renewing: 0,
    memberShipPercent: 0,
    totalMemberShipSold: 0,
    previousWeekFlag: undefined,
  });
  const reportingData = useSelector(
    (state) => state?.admin?.resources?.memberShipReporting?.data
  );

  useEffect(() => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/report/reportingData?reporting_type=member`,
      {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setLoader(false);
        generatePieChartData(response);
        generateDashboardData(response);
        const chartDataArray = [];
        for (let chartData of response) {
          if (chartData.status === "active") {
            chartDataArray.push(chartData);
          }
        }
        generateLineChartData(chartDataArray);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, reportingData]);

  const ParseExcel = async (event) => {
    const file = event.target.files[0];
    const originalData = await getOriginalCsvData(file);

    const allIndexes = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      location: null,
      status: null,
      membership_price: null,
      membership_end_date: null,
      membership_start_date: null,
    };
    const essentialindexes = [
      "email",
      "location",
      "status",
      "membership_start_date",
      "membership_end_date",
    ];
    const parsedFileData = getParsedFileData(
      originalData,
      allIndexes,
      essentialindexes
    );

    if (parsedFileData) {
      setLoader(true);
      for (let parsedData of parsedFileData) {
        const joiningDate = moment(parsedData.joining_date).format(
          "MM/DD/YYYY"
        );
        parsedData.joining_date = joiningDate;
      }
      fetch(`${process.env.REACT_APP_API_URL}/report/newReport`, {
        method: "POST",
        body: JSON.stringify({
          reports: parsedFileData,
          reporting_type: "member",
        }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setTimeout(() => {
            notify(response.message, "success");
          }, 500);
          refresh();
        })
        .catch(() => notify(`Error occurred while uploading`, "warning"));
    } else {
      notify("The uploaded file is not correct", { type: "warning" });
    }
    setLoader(false);
    event.target.value = null;
  };

  const generatePieChartData = (rawData) => {
    const groupedByStatus = mapValues(groupBy(rawData, "status"));
    setGraphData([]);

    for (let source in groupedByStatus) {
      const groupArray = groupedByStatus[source];
      setGraphData((current) => [
        ...current,
        { name: source, value: groupArray.length },
      ]);
    }
  };

  const generateDashboardData = (rawData) => {
    let todayDate = new Date();
    let membershipSold = 0;
    let renewingAmount = 0;
    const previousWeek = [];
    const thisWeek = [];
    let weekStartDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate() - (new Date(todayDate).getDay() - 1)
    );
    let previousWeekStartDate = new Date(
      weekStartDate.getFullYear(),
      weekStartDate.getMonth(),
      weekStartDate.getDate() - 6
    );
    let previousWeekEndDate = new Date(
      previousWeekStartDate.getFullYear(),
      previousWeekStartDate.getMonth(),
      previousWeekStartDate.getDate() + 5
    );
    for (let element of rawData) {
      if (
        new Date(element.membership_start_date) <= previousWeekEndDate &&
        new Date(element.membership_start_date) >= previousWeekStartDate
      ) {
        previousWeek.push(element);
      }
      if (
        new Date(element.membership_start_date) <= todayDate &&
        new Date(element.membership_start_date) >= weekStartDate
      ) {
        thisWeek.push(element);
      }
      if (!(element.status === "payment failure")) {
        membershipSold = membershipSold + Number(element.membership_price);
      }
      if (element.status === "renewing") {
        renewingAmount = renewingAmount + Number(element.membership_price);
      }
    }
    let previousWeekPercentage =
      previousWeek.length === 0
        ? 0
        : (thisWeek.length / previousWeek.length) * 100;
    let previousWeekFlag = thisWeek.length > previousWeek.length ? true : false;
    setDashboardData({
      totalMemberShipSold: membershipSold,
      memberShipPercent: previousWeekPercentage,
      previousWeekFlag: previousWeekFlag,
      renewing: renewingAmount,
    });
  };

  const generateLineChartData = (rawData) => {
    const sortedArray = sortBy(rawData, ["membership_start_date"]);
    const groupedByJoinDate = mapValues(
      groupBy(sortedArray, "membership_start_date")
    );
    setLineChartData([]);

    for (let joinDate in groupedByJoinDate) {
      const groupArray = groupedByJoinDate[joinDate];
      setLineChartData((current) => [
        ...current,
        { date: joinDate, customers: groupArray.length },
      ]);
    }
  };

  const handleTabClick = (values) => {
    setActiveTab(values);
    fetch(
      `${process.env.REACT_APP_API_URL}/report/memberShipChartData?status=${values}`,
      {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        generateLineChartData(response);
      });
  };

  const handleBulkDelete = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_API_URL}/report/deleteAllReports?reporting_type=member`,
      {
        method: "DELETE",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        notify(response.message, { type: "success" });
        refresh();
      });
  };

  return (
    <>
      <Loader loader={loader} />
      <section className="section reporting membership">
        <div className="container">
          <div className="main-title" id="react-admin-title">
            <h3>MemberShips</h3>
            <div className="buttons-wrapper">
              <div className="file-exporter">
                <div>
                  <span>Export</span>
                  <div>
                    <img src={exportPngIcon} alt="" />
                  </div>
                </div>
              </div>

              <div className="d-flex align-initial">
                <a
                  href="https://luminary-analytics.s3.amazonaws.com/sample-csv/membership-sample-csv.csv"
                  download
                >
                  <img src={sampleIcon} alt="" />
                  <span>Download Sample</span>
                </a>

                <label>
                  <input
                    type="file"
                    name="file-input"
                    id="file-upload"
                    accept=".xlsx,.csv"
                    className="file-input__input"
                    onChange={ParseExcel}
                  />
                  <img src={importIcon} alt="" />
                  <span>Import Data</span>
                </label>

                <label onClick={handleBulkDelete}>
                  <span>Reset Data</span>
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="row justify-space-between">
                <div className="col-md-6 stats-box">
                  <h4>MemberShips Sold</h4>
                  <h2>${dashboardData.totalMemberShipSold}</h2>
                  <div className="t-customers">
                    {dashboardData.memberShipPercent !== 0 &&
                      (dashboardData.previousWeekFlag ? (
                        <i className="increment">
                          <AiOutlineArrowUp />
                        </i>
                      ) : (
                        <i className="decrement">
                          <AiOutlineArrowDown />
                        </i>
                      ))}

                    <p>
                      {dashboardData.memberShipPercent.toFixed(2)}% From last
                      week
                    </p>
                  </div>
                </div>

                <div className="col-md-6 stats-box">
                  <h4>Renewing</h4>
                  <h2>${dashboardData.renewing}</h2>
                  <div className="t-customers">
                    {dashboardData.memberShipPercent !== 0 &&
                      (dashboardData.previousWeekFlag ? (
                        <i className="increment">
                          <AiOutlineArrowUp />
                        </i>
                      ) : (
                        <i className="decrement">
                          <AiOutlineArrowDown />
                        </i>
                      ))}

                    <p>
                      {dashboardData.memberShipPercent.toFixed(2)}% From last
                      week
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="chart-container">
                <div className="pie-stats">
                  <h2>MemberShip Status</h2>

                  {graphData.length > 0 ? (
                    <PieChart
                      width={450}
                      height={200}
                      style={{ marginTop: "-10px" }}
                    >
                      <Pie
                        data={graphData}
                        cx={200}
                        cy={100}
                        innerRadius={60}
                        outerRadius={160}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                        key={Math.random()}
                      >
                        {graphData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Legend
                        layout="vertical"
                        iconType="square"
                        width={500}
                        iconSize={18}
                      />
                      <Tooltip
                        offset={0}
                        itemStyle={{
                          backGroung: "red",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      />
                    </PieChart>
                  ) : (
                    <span className="pieNotFound notFound">
                      No Data Found...
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="reporting-detail">
            <div className="chart-wrapper">
              <div className="inline-tabs">
                <ul className="chart-tabs">
                  <li
                    className={activeTab === "active" && "active"}
                    onClick={() => handleTabClick("active")}
                  >
                    Active Members
                  </li>
                  <li
                    className={activeTab === "frozen" && "active"}
                    onClick={() => handleTabClick("frozen")}
                  >
                    Frozen Members
                  </li>
                  <li
                    className={activeTab === "payment failure" && "active"}
                    onClick={() => handleTabClick("payment failure")}
                  >
                    Payment Failure
                  </li>
                </ul>

                <div className="d-flex flex-column">
                  <div className="members-count">
                    {activeTab === "active" ? (
                      <>
                        <div>
                          <img src={starIcon} alt="" />
                          <h4>{lineChartData?.length ?? 0}</h4>
                        </div>
                        <span>Active Member</span>
                      </>
                    ) : activeTab === "frozen" ? (
                      <>
                        <div>
                          <img src={frozenIcon} alt="" />
                          <h4>{lineChartData?.length ?? 0}</h4>
                        </div>
                        <span>Frozen Member</span>
                      </>
                    ) : (
                      <>
                        <div>
                          <img src={payfailureIcon} alt="" />
                          <h4>{lineChartData?.length ?? 0}</h4>
                        </div>
                        <span>Payment Failure</span>
                      </>
                    )}
                  </div>

                  {/* <select className="members-dropdown">
                    <option>Last Week</option>
                    <option>Last Month</option>
                    <option>Last Year</option>
                  </select> */}
                </div>
              </div>
              {lineChartData.length > 0 && (
                <ResponsiveContainer width="98%" aspect={3}>
                  <AreaChart
                    width={730}
                    height={250}
                    data={lineChartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#01D1D1"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#01D1D1"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <CartesianGrid
                      horizontal="true"
                      vertical=""
                      strokeDasharray="10"
                      opacity={0.7}
                    />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="customers"
                      stroke="#01D1D1"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>

          <List
            {...props}
            title=" "
            exporter={false}
            bulkActionButtons={false}
            style={{ display: "none" }}
            filterDefaultValues={{ status: "member" }}
          >
            <div></div>
          </List>
        </div>
      </section>
    </>
  );
};
