import React, { useContext } from "react";
import ChatUserContext from "../../../context/ChatUserContext";

const ChatHeader = ({ selectedUserId }) => {
  const { members } = useContext(ChatUserContext);

  const messageTo = members.find((elem) => {
    return elem?.id === selectedUserId;
  });

  return (
    <div className="channel-header-main">
      <div className="channel__header">
        <img src={messageTo?.profile_image} alt="Avatar" />
        <div className="channel__header__text">
          <h2>{messageTo?.username}</h2>
        </div>
      </div>

      <div className="group-header-icons">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 7V8M11 10.5V15M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
            stroke="#09102A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2222 7.3L14.5859 15.6761C14.4434 17.5517 12.8987 19 11.0408 19H6.95922C5.10131 19 3.55663 17.5517 3.41414 15.6761L2.77778 7.3M17 5.5C14.6907 4.36058 11.9455 3.7 9 3.7C6.05453 3.7 3.30926 4.36058 1 5.5M7.22222 3.7V2.8C7.22222 1.80589 8.01816 1 9 1C9.98184 1 10.7778 1.80589 10.7778 2.8V3.7M7.22222 9.1V14.5M10.7778 9.1V14.5"
            stroke="#09102A"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>

        <svg
          width="22"
          height="22"
          viewBox="10 1 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.25" cy="7.25" r="1.25" fill="#09102A" />
          <circle cx="12.25" cy="12.25" r="1.25" fill="#09102A" />
          <circle cx="12.25" cy="17.25" r="1.25" fill="#09102A" />
        </svg>
      </div>
    </div>
  );
};

export default ChatHeader;
