import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UsersContext from "../../context/UsersContext";
import logonew from "../../assets/images/logonew.png";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChatUserContext from "../../context/ChatUserContext";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "fixed",
      maxWidth: "240px",
      overflow: "auto",
      bottom: "0",
      top: "0",
      color: "#ffffff",
      backgroundColor: "#09102A",
    },
    active: {
      borderLeft: "4px solid #01D1D1",
      "& span": {
        color: "#01D1D1",
        fontWeight: "500",
      },
      "& svg, & ellipse, & circle": {
        stroke: "#01D1D1",
      },
    },
    palette: {
      primary: "#fff",
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
  })
);

const Menu = () => {
  const classes = useStyles();
  const role = localStorage.getItem("role");
  const [openReporting, setOpenReporting] = useState(false);
  const sidebarOpen = useSelector((state) => state.admin?.ui.sidebarOpen);
  const { showSideTabs, sidebarToggle, setSidebarToggle } =
    useContext(UsersContext);
  const {
    members,
    getAllUsers,
    groupNames,
    getGroupNames,
    unReadMsgCount,
    deleteListGroup,
  } = useContext(ChatUserContext);

  useEffect(() => {
    if (!!deleteListGroup) {
      const filteredList = groupNames.filter((groupName) => {
        return groupName.id !== deleteListGroup.groupId;
      });
      getGroupNames(filteredList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteListGroup]);

  useEffect(() => {
    if (!!unReadMsgCount) {
      if (unReadMsgCount.to) {
        const filterMessages = members.filter((passedMsg) => {
          if (passedMsg?.id === unReadMsgCount.to) {
            passedMsg.unread_message_count =
              unReadMsgCount.unread_message_count;
          }
          return members;
        });
        getAllUsers(filterMessages);
      }

      if (unReadMsgCount.group_id) {
        const filterMessages = groupNames.filter((passedMsg) => {
          if (passedMsg?.id === unReadMsgCount.group_id) {
            passedMsg.unread_message_count =
              unReadMsgCount.unread_message_count;
          }
          return groupNames;
        });
        getGroupNames(filterMessages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unReadMsgCount]);

  const ClientsTab = () => {
    if (role === "Super Admin") {
      return (
        <ListItem
          button
          key="clients"
          component={NavLink}
          to={"/clients"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="10"
                  cy="17.5"
                  rx="7"
                  ry="3.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <circle
                  cx="10"
                  cy="7"
                  r="4"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.0447 10.2496C14.7227 10.7485 14.3286 11.1965 13.8773 11.5791C14.2318 11.6901 14.6089 11.75 15 11.75C17.071 11.75 18.75 10.0711 18.75 7.99999C18.75 6.04422 17.2528 4.43814 15.342 4.26538C15.6082 4.78435 15.801 5.34717 15.9067 5.94015C16.6978 6.28887 17.25 7.07994 17.25 7.99999C17.25 9.2277 16.2667 10.2257 15.0447 10.2496Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.9995 17.5563C18.9895 18.1705 18.8146 18.7606 18.5008 19.3108C18.8692 19.2095 19.2143 19.092 19.5311 18.96C20.1282 18.7112 20.6604 18.3959 21.0548 18.0074C21.4519 17.6162 21.75 17.1064 21.75 16.5C21.75 15.8935 21.4519 15.3837 21.0548 14.9925C20.6604 14.604 20.1282 14.2887 19.5311 14.0399C18.5085 13.6138 17.1906 13.3394 15.7494 13.2683C16.7516 13.7774 17.5701 14.4169 18.135 15.1443C18.4327 15.2274 18.7071 15.3215 18.9542 15.4245C19.4428 15.6281 19.7893 15.8514 20.0022 16.0611C20.2124 16.2682 20.25 16.416 20.25 16.5C20.25 16.5839 20.2124 16.7317 20.0022 16.9388C19.796 17.1419 19.4643 17.3579 18.9995 17.5563Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const OwnersTab = () => {
    if (role === "Super Admin" || role === "Client") {
      return (
        <ListItem
          button
          key="owners"
          component={NavLink}
          to={"/owners"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="10"
                  cy="17.5"
                  rx="7"
                  ry="3.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <circle
                  cx="10"
                  cy="7"
                  r="4"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.0447 10.2496C14.7227 10.7485 14.3286 11.1965 13.8773 11.5791C14.2318 11.6901 14.6089 11.75 15 11.75C17.071 11.75 18.75 10.0711 18.75 7.99999C18.75 6.04422 17.2528 4.43814 15.342 4.26538C15.6082 4.78435 15.801 5.34717 15.9067 5.94015C16.6978 6.28887 17.25 7.07994 17.25 7.99999C17.25 9.2277 16.2667 10.2257 15.0447 10.2496Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.9995 17.5563C18.9895 18.1705 18.8146 18.7606 18.5008 19.3108C18.8692 19.2095 19.2143 19.092 19.5311 18.96C20.1282 18.7112 20.6604 18.3959 21.0548 18.0074C21.4519 17.6162 21.75 17.1064 21.75 16.5C21.75 15.8935 21.4519 15.3837 21.0548 14.9925C20.6604 14.604 20.1282 14.2887 19.5311 14.0399C18.5085 13.6138 17.1906 13.3394 15.7494 13.2683C16.7516 13.7774 17.5701 14.4169 18.135 15.1443C18.4327 15.2274 18.7071 15.3215 18.9542 15.4245C19.4428 15.6281 19.7893 15.8514 20.0022 16.0611C20.2124 16.2682 20.25 16.416 20.25 16.5C20.25 16.5839 20.2124 16.7317 20.0022 16.9388C19.796 17.1419 19.4643 17.3579 18.9995 17.5563Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Owners" />
        </ListItem>
      );
    } else {
      return null;
    }
  };



  const MindBodyTab = () => {
    if (role === "Owner") {
      return (
        <ListItem
          button
          key="owners"
          component={NavLink}
          to={"/mindbody"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="10"
                  cy="17.5"
                  rx="7"
                  ry="3.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <circle
                  cx="10"
                  cy="7"
                  r="4"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.0447 10.2496C14.7227 10.7485 14.3286 11.1965 13.8773 11.5791C14.2318 11.6901 14.6089 11.75 15 11.75C17.071 11.75 18.75 10.0711 18.75 7.99999C18.75 6.04422 17.2528 4.43814 15.342 4.26538C15.6082 4.78435 15.801 5.34717 15.9067 5.94015C16.6978 6.28887 17.25 7.07994 17.25 7.99999C17.25 9.2277 16.2667 10.2257 15.0447 10.2496Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.9995 17.5563C18.9895 18.1705 18.8146 18.7606 18.5008 19.3108C18.8692 19.2095 19.2143 19.092 19.5311 18.96C20.1282 18.7112 20.6604 18.3959 21.0548 18.0074C21.4519 17.6162 21.75 17.1064 21.75 16.5C21.75 15.8935 21.4519 15.3837 21.0548 14.9925C20.6604 14.604 20.1282 14.2887 19.5311 14.0399C18.5085 13.6138 17.1906 13.3394 15.7494 13.2683C16.7516 13.7774 17.5701 14.4169 18.135 15.1443C18.4327 15.2274 18.7071 15.3215 18.9542 15.4245C19.4428 15.6281 19.7893 15.8514 20.0022 16.0611C20.2124 16.2682 20.25 16.416 20.25 16.5C20.25 16.5839 20.2124 16.7317 20.0022 16.9388C19.796 17.1419 19.4643 17.3579 18.9995 17.5563Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="MindBody" />
        </ListItem>
      );
    } else {
      return null;
    }
  };


  const StaffTab = () => {
    if (role !== "Staff" && showSideTabs?.staff_management) {
      return (
        <ListItem
          button
          key="staff"
          component={NavLink}
          to={"/staff"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="12"
                  cy="16.5"
                  rx="6"
                  ry="2.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <circle
                  cx="12"
                  cy="8"
                  r="3"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.44547 13.2615C5.20689 13.313 4.06913 13.5361 3.18592 13.8894C2.68122 14.0913 2.22245 14.3505 1.87759 14.6766C1.53115 15.0042 1.25 15.4512 1.25 16C1.25 16.5488 1.53115 16.9958 1.87759 17.3234C2.22245 17.6495 2.68122 17.9087 3.18592 18.1106C3.68571 18.3105 4.26701 18.4687 4.90197 18.5778C4.40834 18.0453 4.09852 17.4503 4.01985 16.8195C3.92341 16.787 3.83104 16.7531 3.74301 16.7179C3.34289 16.5578 3.06943 16.386 2.90826 16.2336C2.7498 16.0837 2.74999 16.0046 2.75 16.0001L2.75 16L2.75 15.9999C2.74999 15.9954 2.7498 15.9163 2.90826 15.7664C3.06943 15.614 3.34289 15.4422 3.74301 15.2821C3.94597 15.201 4.17201 15.1266 4.41787 15.0608C4.83157 14.371 5.53447 13.756 6.44547 13.2615Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.9802 16.8195C19.9015 17.4503 19.5917 18.0453 19.0981 18.5778C19.733 18.4687 20.3143 18.3105 20.8141 18.1106C21.3188 17.9087 21.7776 17.6495 22.1224 17.3234C22.4689 16.9958 22.75 16.5488 22.75 16C22.75 15.4512 22.4689 15.0042 22.1224 14.6766C21.7776 14.3505 21.3188 14.0913 20.8141 13.8894C19.9309 13.5361 18.7931 13.313 17.5546 13.2615C18.4656 13.756 19.1685 14.371 19.5822 15.0608C19.828 15.1266 20.0541 15.201 20.257 15.2821C20.6571 15.4422 20.9306 15.614 21.0918 15.7664C21.2502 15.9163 21.25 15.9954 21.25 15.9999V16V16.0001C21.25 16.0046 21.2502 16.0837 21.0918 16.2336C20.9306 16.386 20.6571 16.5578 20.257 16.7179C20.169 16.7531 20.0766 16.787 19.9802 16.8195Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.5144 10.1522C16.2945 10.6126 16.0063 11.0341 15.6628 11.4036C16.0586 11.6243 16.5146 11.75 17 11.75C18.5188 11.75 19.75 10.5188 19.75 9C19.75 7.48122 18.5188 6.25 17 6.25C16.8957 6.25 16.7928 6.2558 16.6915 6.26711C16.8636 6.73272 16.9683 7.23096 16.9939 7.75001C16.9959 7.75 16.9979 7.75 17 7.75C17.6904 7.75 18.25 8.30964 18.25 9C18.25 9.69036 17.6904 10.25 17 10.25C16.8278 10.25 16.6637 10.2152 16.5144 10.1522Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.30845 6.26711C7.20719 6.2558 7.10427 6.25 7 6.25C5.48122 6.25 4.25 7.48122 4.25 9C4.25 10.5188 5.48122 11.75 7 11.75C7.48537 11.75 7.94138 11.6243 8.33721 11.4036C7.99374 11.0341 7.70549 10.6126 7.4856 10.1522C7.33631 10.2152 7.17222 10.25 7 10.25C6.30964 10.25 5.75 9.69036 5.75 9C5.75 8.30964 6.30964 7.75 7 7.75C7.00205 7.75 7.00409 7.75 7.00614 7.75001C7.0317 7.23096 7.13641 6.73272 7.30845 6.26711Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Staff" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const LocationTab = () => {
    if (role === "Client" || role === "Super Admin") {
      return (
        <ListItem
          button
          component={NavLink}
          to={"/location"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 10.8889C20.25 13.0928 18.9651 15.7033 17.1927 17.8014C16.3165 18.8387 15.3496 19.7157 14.4183 20.3274C13.4717 20.9492 12.6339 21.25 12 21.25V22.75C13.0536 22.75 14.1845 22.2756 15.2418 21.5811C16.3145 20.8766 17.3867 19.8962 18.3386 18.7694C20.2224 16.5393 21.75 13.5941 21.75 10.8889H20.25ZM12 21.25C11.3661 21.25 10.5283 20.9492 9.58168 20.3274C8.65039 19.7157 7.68353 18.8387 6.80731 17.8014C5.03493 15.7033 3.75 13.0928 3.75 10.8889H2.25C2.25 13.5941 3.77757 16.5393 5.66144 18.7694C6.61334 19.8962 7.68554 20.8766 8.75817 21.5811C9.81548 22.2756 10.9464 22.75 12 22.75V21.25ZM3.75 10.8889C3.75 6.40266 7.43484 2.75 12 2.75V1.25C6.62403 1.25 2.25 5.55672 2.25 10.8889H3.75ZM12 2.75C16.5652 2.75 20.25 6.40266 20.25 10.8889H21.75C21.75 5.55672 17.376 1.25 12 1.25V2.75ZM14.25 11C14.25 12.2426 13.2426 13.25 12 13.25V14.75C14.0711 14.75 15.75 13.0711 15.75 11H14.25ZM12 13.25C10.7574 13.25 9.75 12.2426 9.75 11H8.25C8.25 13.0711 9.92893 14.75 12 14.75V13.25ZM9.75 11C9.75 9.75736 10.7574 8.75 12 8.75V7.25C9.92893 7.25 8.25 8.92893 8.25 11H9.75ZM12 8.75C13.2426 8.75 14.25 9.75736 14.25 11H15.75C15.75 8.92893 14.0711 7.25 12 7.25V8.75Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Locations" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const LeadsTab = () => {
    if (role === "Super Admin") {
      return (
        <ListItem
          button
          component={NavLink}
          to={"/leads"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14V17M11 12V17M15 14V17M19 12V17M2 2V18C2 20.2091 3.79086 22 6 22H22M7 10L9.58579 7.41421C10.3668 6.63316 11.6332 6.63317 12.4142 7.41421L13.5858 8.58579C14.3668 9.36684 15.6332 9.36683 16.4142 8.58579L19 6"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Leads" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const DocsManagementTab = () => {
    if (role === "Client" && showSideTabs?.documents) {
      return (
        <ListItem
          button
          component={NavLink}
          to={"/documentTypeManagement"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6.25C5.58579 6.25 5.25 6.58579 5.25 7C5.25 7.41421 5.58579 7.75 6 7.75V6.25ZM14 7.75C14.4142 7.75 14.75 7.41421 14.75 7C14.75 6.58579 14.4142 6.25 14 6.25V7.75ZM6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75V11.25ZM14 12.75C14.4142 12.75 14.75 12.4142 14.75 12C14.75 11.5858 14.4142 11.25 14 11.25V12.75ZM6 16.25C5.58579 16.25 5.25 16.5858 5.25 17C5.25 17.4142 5.58579 17.75 6 17.75V16.25ZM10 17.75C10.4142 17.75 10.75 17.4142 10.75 17C10.75 16.5858 10.4142 16.25 10 16.25V17.75ZM5 2.75H15V1.25H5V2.75ZM20 21.25H5V22.75H20V21.25ZM2.75 19V5H1.25V19H2.75ZM18 8.75H20V7.25H18V8.75ZM21.25 10V20H22.75V10H21.25ZM18.75 20V8H17.25V20H18.75ZM20 21.25C19.3096 21.25 18.75 20.6904 18.75 20H17.25C17.25 21.5188 18.4812 22.75 20 22.75V21.25ZM21.25 20C21.25 20.6904 20.6904 21.25 20 21.25V22.75C21.5188 22.75 22.75 21.5188 22.75 20H21.25ZM17.25 5V8H18.75V5H17.25ZM5 21.25C3.75736 21.25 2.75 20.2426 2.75 19H1.25C1.25 21.0711 2.92893 22.75 5 22.75V21.25ZM15 2.75C16.2426 2.75 17.25 3.75736 17.25 5H18.75C18.75 2.92893 17.0711 1.25 15 1.25V2.75ZM20 8.75C20.6904 8.75 21.25 9.30964 21.25 10H22.75C22.75 8.48122 21.5188 7.25 20 7.25V8.75ZM5 1.25C2.92893 1.25 1.25 2.92893 1.25 5H2.75C2.75 3.75736 3.75736 2.75 5 2.75V1.25ZM6 7.75H14V6.25H6V7.75ZM6 12.75H14V11.25H6V12.75ZM6 17.75H10V16.25H6V17.75Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Docs Management" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const DirectMessageTab = () => {
    if (role === "Client" || role === "Owner" || role === "Staff") {
      if (showSideTabs?.direct_chat) {
        return (
          <>
            <ListItem
              button
              component={NavLink}
              to={"/messages"}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 9.25C7.58579 9.25 7.25 9.58579 7.25 10C7.25 10.4142 7.58579 10.75 8 10.75V9.25ZM16 10.75C16.4142 10.75 16.75 10.4142 16.75 10C16.75 9.58579 16.4142 9.25 16 9.25V10.75ZM8 13.25C7.58579 13.25 7.25 13.5858 7.25 14C7.25 14.4142 7.58579 14.75 8 14.75V13.25ZM12 14.75C12.4142 14.75 12.75 14.4142 12.75 14C12.75 13.5858 12.4142 13.25 12 13.25V14.75ZM11 3.75H13V2.25H11V3.75ZM2.75 17V12H1.25V17H2.75ZM13 20.25H6V21.75H13V20.25ZM1.25 17C1.25 19.6234 3.37665 21.75 6 21.75V20.25C4.20507 20.25 2.75 18.7949 2.75 17H1.25ZM21.25 12C21.25 16.5563 17.5563 20.25 13 20.25V21.75C18.3848 21.75 22.75 17.3848 22.75 12H21.25ZM13 3.75C17.5563 3.75 21.25 7.44365 21.25 12H22.75C22.75 6.61522 18.3848 2.25 13 2.25V3.75ZM11 2.25C5.61522 2.25 1.25 6.61522 1.25 12H2.75C2.75 7.44365 6.44365 3.75 11 3.75V2.25ZM8 10.75H16V9.25H8V10.75ZM8 14.75H12V13.25H8V14.75Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </ListItemIcon>
              <ListItemText primary="Direct Message" />
            </ListItem>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const GroupChatTab = () => {
    if (role === "Client" || role === "Owner" || role === "Staff") {
      if (showSideTabs?.group_chat) {
        return (
          <>
            <ListItem
              button
              component={NavLink}
              to={"/groupChats"}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3726 8.17276L16.6427 8.34532L17.3726 8.17276ZM9.77778 3.75H10.8889V2.25H9.77778V3.75ZM2.75 14.25V10.875H1.25V14.25H2.75ZM1.25 14.25C1.25 15.898 2.57195 17.25 4.22222 17.25V15.75C3.41789 15.75 2.75 15.0872 2.75 14.25H1.25ZM16.8056 9.75C16.8056 13.0725 14.1478 15.75 10.8889 15.75V17.25C14.9938 17.25 18.3056 13.8833 18.3056 9.75H16.8056ZM9.77778 2.25C5.05926 2.25 1.25 6.12036 1.25 10.875H2.75C2.75 6.93116 5.9052 3.75 9.77778 3.75V2.25ZM22.75 18.75V15.375H21.25V18.75H22.75ZM13.1111 21.75H19.7778V20.25H13.1111V21.75ZM21.25 18.75C21.25 19.5872 20.5821 20.25 19.7778 20.25V21.75C21.428 21.75 22.75 20.398 22.75 18.75H21.25ZM17.0653 8.85694C19.528 9.96287 21.25 12.4631 21.25 15.375H22.75C22.75 11.8592 20.6691 8.83102 17.6798 7.48858L17.0653 8.85694ZM10.8889 3.75C13.6728 3.75 16.018 5.70302 16.6427 8.34532L18.1025 8.00019C17.3242 4.70829 14.3959 2.25 10.8889 2.25V3.75ZM16.6427 8.34532C16.749 8.79515 16.8056 9.26538 16.8056 9.75H18.3056C18.3056 9.14839 18.2354 8.56235 18.1025 8.00019L16.6427 8.34532ZM6.1157 16.7472C7.13109 19.6559 9.87481 21.75 13.1111 21.75V20.25C10.542 20.25 8.34688 18.5875 7.53189 16.2528L6.1157 16.7472ZM10.8889 15.75H6.82379V17.25H10.8889V15.75ZM6.82379 15.75H4.22222V17.25H6.82379V15.75Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </ListItemIcon>
              <ListItemText primary="Group Chat" />
            </ListItem>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const ReportingTab = () => {
    if (role === "Client" || role === "Owner" || role === "Staff") {
      if (
        showSideTabs?.customer_reporting ||
        showSideTabs?.membership_reporting
      ) {
        return (
          <>
            <ListItem button onClick={() => setOpenReporting(!openReporting)}>
              <ListItemIcon>
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 14V17M11 12V17M15 14V17M19 12V17M2 2V18C2 20.2091 3.79086 22 6 22H22M7 10L9.58579 7.41421C10.3668 6.63316 11.6332 6.63317 12.4142 7.41421L13.5858 8.58579C14.3668 9.36684 15.6332 9.36683 16.4142 8.58579L19 6"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </ListItemIcon>
              <ListItemText primary="Reporting" />
              {openReporting ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              className="submenu"
              in={openReporting}
              timeout="auto"
              unmountOnExit
            >
              {showSideTabs?.customer_reporting && (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={NavLink}
                    to={"/customerReporting"}
                    activeClassName={classes.active}
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Customers" />
                  </ListItem>
                </List>
              )}

              {showSideTabs?.membership_reporting && (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={NavLink}
                    to={"/memberShipReporting"}
                    activeClassName={classes.active}
                    className={classes.nested}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary="Membership" />
                  </ListItem>
                </List>
              )}
            </Collapse>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const DocumentsTab = () => {
    if (showSideTabs?.documents) {
      return (
        <ListItem
          button
          component={NavLink}
          to={"/document"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6.25C5.58579 6.25 5.25 6.58579 5.25 7C5.25 7.41421 5.58579 7.75 6 7.75V6.25ZM14 7.75C14.4142 7.75 14.75 7.41421 14.75 7C14.75 6.58579 14.4142 6.25 14 6.25V7.75ZM6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75V11.25ZM14 12.75C14.4142 12.75 14.75 12.4142 14.75 12C14.75 11.5858 14.4142 11.25 14 11.25V12.75ZM6 16.25C5.58579 16.25 5.25 16.5858 5.25 17C5.25 17.4142 5.58579 17.75 6 17.75V16.25ZM10 17.75C10.4142 17.75 10.75 17.4142 10.75 17C10.75 16.5858 10.4142 16.25 10 16.25V17.75ZM5 2.75H15V1.25H5V2.75ZM20 21.25H5V22.75H20V21.25ZM2.75 19V5H1.25V19H2.75ZM18 8.75H20V7.25H18V8.75ZM21.25 10V20H22.75V10H21.25ZM18.75 20V8H17.25V20H18.75ZM20 21.25C19.3096 21.25 18.75 20.6904 18.75 20H17.25C17.25 21.5188 18.4812 22.75 20 22.75V21.25ZM21.25 20C21.25 20.6904 20.6904 21.25 20 21.25V22.75C21.5188 22.75 22.75 21.5188 22.75 20H21.25ZM17.25 5V8H18.75V5H17.25ZM5 21.25C3.75736 21.25 2.75 20.2426 2.75 19H1.25C1.25 21.0711 2.92893 22.75 5 22.75V21.25ZM15 2.75C16.2426 2.75 17.25 3.75736 17.25 5H18.75C18.75 2.92893 17.0711 1.25 15 1.25V2.75ZM20 8.75C20.6904 8.75 21.25 9.30964 21.25 10H22.75C22.75 8.48122 21.5188 7.25 20 7.25V8.75ZM5 1.25C2.92893 1.25 1.25 2.92893 1.25 5H2.75C2.75 3.75736 3.75736 2.75 5 2.75V1.25ZM6 7.75H14V6.25H6V7.75ZM6 12.75H14V11.25H6V12.75ZM6 17.75H10V16.25H6V17.75Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Documents" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const PricingTab = () => {
    if (role === "Client") {
      return (
        <ListItem
          button
          component={NavLink}
          to={"/pricing"}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6.25C5.58579 6.25 5.25 6.58579 5.25 7C5.25 7.41421 5.58579 7.75 6 7.75V6.25ZM14 7.75C14.4142 7.75 14.75 7.41421 14.75 7C14.75 6.58579 14.4142 6.25 14 6.25V7.75ZM6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75V11.25ZM14 12.75C14.4142 12.75 14.75 12.4142 14.75 12C14.75 11.5858 14.4142 11.25 14 11.25V12.75ZM6 16.25C5.58579 16.25 5.25 16.5858 5.25 17C5.25 17.4142 5.58579 17.75 6 17.75V16.25ZM10 17.75C10.4142 17.75 10.75 17.4142 10.75 17C10.75 16.5858 10.4142 16.25 10 16.25V17.75ZM5 2.75H15V1.25H5V2.75ZM20 21.25H5V22.75H20V21.25ZM2.75 19V5H1.25V19H2.75ZM18 8.75H20V7.25H18V8.75ZM21.25 10V20H22.75V10H21.25ZM18.75 20V8H17.25V20H18.75ZM20 21.25C19.3096 21.25 18.75 20.6904 18.75 20H17.25C17.25 21.5188 18.4812 22.75 20 22.75V21.25ZM21.25 20C21.25 20.6904 20.6904 21.25 20 21.25V22.75C21.5188 22.75 22.75 21.5188 22.75 20H21.25ZM17.25 5V8H18.75V5H17.25ZM5 21.25C3.75736 21.25 2.75 20.2426 2.75 19H1.25C1.25 21.0711 2.92893 22.75 5 22.75V21.25ZM15 2.75C16.2426 2.75 17.25 3.75736 17.25 5H18.75C18.75 2.92893 17.0711 1.25 15 1.25V2.75ZM20 8.75C20.6904 8.75 21.25 9.30964 21.25 10H22.75C22.75 8.48122 21.5188 7.25 20 7.25V8.75ZM5 1.25C2.92893 1.25 1.25 2.92893 1.25 5H2.75C2.75 3.75736 3.75736 2.75 5 2.75V1.25ZM6 7.75H14V6.25H6V7.75ZM6 12.75H14V11.25H6V12.75ZM6 17.75H10V16.25H6V17.75Z"
                  fill="white"
                />
              </svg>
            </div>
          </ListItemIcon>
          <ListItemText primary="Pricing Plans" />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  return (
    <List
      id="sidebar"
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root + (sidebarOpen ? " halfmenuclosed" : "")}
    >
      <div className={`logo-wrapper ${!sidebarToggle && "closedSidebar"}`}>
        {sidebarToggle && <img src={logonew} alt="" />}
        <i onClick={() => setSidebarToggle(!sidebarToggle)}>
          {sidebarToggle ? (
            <FiChevronLeft size={20} />
          ) : (
            <FiChevronRight size={20} />
          )}
        </i>
      </div>
      {[
        ClientsTab(),
        OwnersTab(),
        MindBodyTab(),
        StaffTab(),
        LeadsTab(),
        LocationTab(),
        DocumentsTab(),
        DocsManagementTab(),
      ]}
      {[DirectMessageTab(), GroupChatTab(), PricingTab(), ReportingTab()]}
    </List>
  );
};

export default Menu;
