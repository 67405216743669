export const validationfunction = {
  first_name: (cellStr) => {
    return /^(first\s*name\.?)[ ]*$/i.test(cellStr);
  },
  last_name: (cellStr) => {
    return /^(last\s*name\.?)[ ]*$/i.test(cellStr);
  },
  location: (cellStr) => {
    return /^location[ ]*$/i.test(cellStr);
  },
  email: (cellStr) => {
    return /^email[ ]*$/i.test(cellStr);
  },
  phone: (cellStr) => {
    return /^phone[ ]*$/i.test(cellStr);
  },
  postal_code: (cellStr) => {
    return /^postal_code[ ]*$/i.test(cellStr);
  },

  // Customer Reporting
  joining_date: (cellStr) => {
    return /^(joining\s*date\.?)[ ]*$/i.test(cellStr);
  },
  joining_source: (cellStr) => {
    return /^(joining\s*source\.?)[ ]*$/i.test(cellStr);
  },

  // Membership Reporting
  membership_start_date: (cellStr) => {
    return /^(membership\s*start\s*date\.?)[ ]*$/i.test(cellStr);
  },
  membership_end_date: (cellStr) => {
    return /^(membership\s*end\s*date\.?)[ ]*$/i.test(cellStr);
  },
  membership_price: (cellStr) => {
    return /^(membership\s*price\.?)[ ]*$/i.test(cellStr);
  },
  status: (cellStr) => {
    return /^status[ ]*$/i.test(cellStr);
  },

  // Locations
  name: (cellStr) => {
    return /^name[ ]*$/i.test(cellStr);
  },
  country: (cellStr) => {
    return /^country[ ]*$/i.test(cellStr);
  },
  city: (cellStr) => {
    return /^city[ ]*$/i.test(cellStr);
  },
  address: (cellStr) => {
    return /^address[ ]*$/i.test(cellStr);
  },

  // Users
  full_name: (cellStr) => {
    return /^full_name[ ]*$/i.test(cellStr);
  },
  username: (cellStr) => {
    return /^username[ ]*$/i.test(cellStr);
  },
  locations: (cellStr) => {
    return /^locations[ ]*$/i.test(cellStr);
  },
};
