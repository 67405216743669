import { CreateButton } from "react-admin";
import Box from "@material-ui/core/Box";
import InboxIcon from "@material-ui/icons/Inbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: "500px",
      paddingTop: theme.spacing(10),
      fontSize: "170px",
      color: "#ffffff",
    },
    heading: {
      fontSize: "30px",
      paddingBottom: theme.spacing(4),
    },
  })
);

export const EmptyList = ({ itemName, basePath, isCreateAble = false }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} textAlign="center" m={1}>
      <InboxIcon fontSize="inherit" htmlColor="#09102A" />

      <Typography
        style={{ color: "#09102A" }}
        className={classes.heading}
        variant="subtitle1"
      >
        Nothing to display here. This list is empty
      </Typography>

      {isCreateAble && (
        <CreateButton
          size="large"
          key={`empty-page-create-button`}
          basePath={basePath}
        />
      )}
    </Box>
  );
};
