import blocked from "../../../assets/images/blocked.png";
import unblocked from "../../../assets/images/unblocked.png";

export const BlockedList = ({ record }) => {
  return [
    record.status === "Active" && (
      <img src={unblocked} className="ml-3" alt="" />
    ),
    record.status === "Block" && <img src={blocked} className="ml-3" alt="" />,
  ];
};
