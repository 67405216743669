import React from "react";

const GroupInfoMenu = ({
  setOpen,
  groupName,
  showGroupMembers,
  showMemberListMenu,
  deleteMemberHandler,
  setShowMemberListMenu,
}) => {
  const groupCreatorId = localStorage.getItem("id");

  return (
    <div className="group-chat-leftside">
      {showGroupMembers.map((elem, index) => {
        return (
          <div key={index}>
            <div className="left-side">
              <div className=" leftside-header">
                <img src={elem?.group_icon} alt="Avatar" />
                <h2>{elem?.title}</h2>
                <p>{elem?.description}</p>
                <div className="creater__div">
                  <span>Created by: </span>
                  <span className="group__creater">{elem?.user_full_name}</span>
                </div>
              </div>
            </div>
            <div className="leftside-member">
              <h4> {elem?.group_members_count} Members </h4>
              {groupCreatorId === groupName?.group_creator && (
                <button>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setOpen(true)}
                  >
                    <ellipse
                      cx="10"
                      cy="17.5"
                      rx="7"
                      ry="3.5"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="10"
                      cy="7"
                      r="4"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 11H17"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M19 9L19 13"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              )}
            </div>

            <div className="leftside-list">
              {elem.group_members.map((member, index) => {
                return (
                  <div className="list-card" key={index}>
                    <div className="d-flex">
                      <img
                        className="list-card-avatar"
                        src={member.profile_image}
                        alt="Avatar"
                      />
                      <div className="list-member-name">
                        <h3>{member.user_name}</h3>
                        <p>{member.role}</p>
                      </div>
                    </div>
                    <div className="list-card-dots">
                      <div className="userdropdown">
                        <div className="dropdown copybtn align-items-center">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() =>
                              setShowMemberListMenu({
                                showValue: !showMemberListMenu.showValue,
                                jobId: member?._id,
                              })
                            }
                          >
                            <circle
                              cx="12.25"
                              cy="7.25"
                              r="1.25"
                              fill="#09102A"
                            />
                            <circle
                              cx="12.25"
                              cy="12.25"
                              r="1.25"
                              fill="#09102A"
                            />
                            <circle
                              cx="12.25"
                              cy="17.25"
                              r="1.25"
                              fill="#09102A"
                            />
                          </svg>

                          <div
                            className={`dropdown-menu dropdown-menu-right ${
                              showMemberListMenu.showValue &&
                              showMemberListMenu.jobId === member._id
                                ? "show"
                                : null
                            }`}
                          >
                            <div
                              className="dropdown-item sharelink"
                              onClick={() => {
                                deleteMemberHandler(member._id);
                                setShowMemberListMenu({
                                  ...showMemberListMenu,
                                  showValue: false,
                                });
                              }}
                            >
                              Remove Member
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GroupInfoMenu;
