import { createContext, useState } from "react";

const ChatUserContext = createContext();

export function ChatProvider({ children }) {
  const [members, setMembers] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [unReadMsgCount, setUnReadMsgCount] = useState([]);
  const [deleteListGroup, setDeleteListGroup] = useState([]);

  const getAllUsers = (data) => {
    setMembers(data);
  };

  const getGroupNames = (data) => {
    setGroupNames(data);
  };

  return (
    <ChatUserContext.Provider
      value={{
        members,
        getAllUsers,
        groupNames,
        getGroupNames,
        deleteListGroup,
        setDeleteListGroup,
        unReadMsgCount,
        setUnReadMsgCount,
      }}
    >
      {children}
    </ChatUserContext.Provider>
  );
}

export default ChatUserContext;
