import { SaveButton, DeleteButton, Toolbar } from "react-admin";
import {
  Box,
  Backdrop,
  Typography,
  makeStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: 1,
      width: "100%",
    },
    toolbar: {
      backgroundColor: theme.palette.invisible,
    },
  })
);

export const EditBottomToolBar = ({
  isDeleteAble = false,
  showProgress = false,
  progressValue = 0,
  progressLabel = "Please wait...",
  ...props
}) => {
  const classes = useStyles();
  return (
    <Toolbar classesName={classes.toolbar} {...props}>
      <Backdrop open={showProgress} className={classes.backdrop}>
        <CircularProgress
          variant="determinate"
          value={progressValue}
          size={80}
          thickness={2.2}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" component="div" color="textSecondary">
            {`${Math.round(progressValue)}%`}
          </Typography>
        </Box>

        <Box
          top={240}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" component="h1" color="textSecondary">
            {progressLabel}
          </Typography>
        </Box>
      </Backdrop>

      <SaveButton disabled={props.pristine} label="Save Changes" />

      {isDeleteAble ? <DeleteButton undoable={false} color="error" /> : null}
    </Toolbar>
  );
};
