import {
  List,
  Datagrid,
  DateField,
  ChipField,
  TextField,
  ArrayField,
  TopToolbar,
  FunctionField,
  useListContext,
  SingleFieldList,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import Filters from "./Filters";
import { useState, cloneElement } from "react";
import Loader from "../../General/views/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { EmptyList } from "../../General/views/EmptyList";
import { ListActions } from "../../General/utils/ListActions";
import { ViewActions } from "../../General/utils/ViewActions";
import sortedIcon from "../../../assets/images/sortedIcon.png";
import CreateDocumentModal from "../Create/CreateDocumentModal";
import exportPngIcon from "../../../assets/images/exportPngIcon.png";

const useStyles = makeStyles({
  sort: {
    "&&:after": {
      content: '""',
      height: "18px",
      width: "18px",
      marginLeft: "4px",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      verticalAlign: "middle",
      background: `url(${sortedIcon})`,
    },
  },
});

const TabbedDatagrid = (props) => {
  const classes = useStyles();
  const listContext = useListContext();
  const role = localStorage.getItem("role");
  if (props.loading) {
    return <Loader loader={props.loading} />;
  }

  const StringToLabelObject = ({ record, children, ...rest }) =>
    cloneElement(children, {
      record: { label: record },
      ...rest,
    });

  return (
    <ListContextProvider value={{ ...listContext }}>
      <Datagrid
        {...props}
        optimized
        rowClick={false}
        empty={<EmptyList itemName={"Reports"} {...props} />}
      >
        <TextField source="serial_no" label="No" sortable={false} />
        <TextField source="title" label="Name" headerClassName={classes.sort} />
        <ArrayField source="locations" headerClassName={classes.sort}>
          <SingleFieldList linkType={false}>
            <StringToLabelObject>
              <ChipField source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <TextField
          source="type"
          label="Document Type"
          headerClassName={classes.sort}
        />
        <ArrayField source="access_level" headerClassName={classes.sort}>
          <SingleFieldList linkType={false}>
            <StringToLabelObject>
              <ChipField source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <DateField
          source="created_at"
          label="Created At"
          headerClassName={classes.sort}
        />

        {role === "Client" ? (
          <FunctionField
            label=""
            render={(record) => (
              <ListActions record={record} resource={props.resource} />
            )}
          />
        ) : (
          <FunctionField
            label=""
            render={(record) => (
              <ViewActions record={record} resource={props.resource} />
            )}
          />
        )}
      </Datagrid>
    </ListContextProvider>
  );
};

const TableListActions = () => {
  return <TopToolbar></TopToolbar>;
  // return <TopToolbar>{role === "Client" && <CreateButton />}</TopToolbar>;
};

export const DocumentsList = (props) => {
  useAuthenticated();
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");

  return (
    <section className="section">
      <div className="main-title">
        <h2>Documents</h2>
        <div className="file-exporter">
          <div>
            <span>Export</span>
            <div>
              <img src={exportPngIcon} alt="" />
            </div>
          </div>
          {role === "Client" && (
            <button onClick={() => setOpen(true)}>+ Create Document</button>
          )}
        </div>
      </div>
      <CreateDocumentModal open={open} setOpen={setOpen} props={props} />

      <List
        {...props}
        exporter={false}
        title=" "
        filters={<Filters />}
        bulkActionButtons={false}
        actions={<TableListActions />}
        filterDefaultValues={{ status: "all" }}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <TabbedDatagrid />
      </List>
    </section>
  );
};
