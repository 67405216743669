import { TextInput, required, SelectInput } from "react-admin";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    subHeading: {
      fontWeight: 500,
      fontSize: "22px",
      marginBottom: "30px",
      color: "#5B6280!important",
      paddingLeft: "15px",
    },
    ptb: {
      paddingTop: "15px!important",
      paddingBottom: "0!important",
      paddingLeft: "15px!important",
      paddingRight: "15px!important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
    inputField: {
      width: "100%",
      borderRadius: "6px",
    },
  })
);

export const BasicDetails = (props) => {
  const classes = useStyles();

  return [
    <Box component="section" className={`${classes.root} myforms`}>
      <Grid style={{ width: "100%" }} container>
        <Typography variant="h6" className={classes.subHeading}>
          Lead Details
        </Typography>

        <div className="row">
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              disabled
              fullWidth
              source="first_name"
              validate={required()}
              className={classes.inputField}
            />
          </Grid>

          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              disabled
              fullWidth
              source="last_name"
              validate={required()}
              className={classes.inputField}
            />
          </Grid>

          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              disabled
              fullWidth
              source="email"
              validate={required()}
              className={classes.inputField}
            />
          </Grid>

          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <SelectInput
              fullWidth
              label="Status"
              source="status"
              className={classes.inputField}
              choices={[
                { id: "pending", name: "Pending" },
                { id: "approve", name: "Approve" },
                { id: "reject", name: "Reject" },
              ]}
            />
          </Grid>

          <Grid className={classes.ptb} item xl={12} md={12} sm={12}>
            <TextInput
              disabled
              fullWidth
              source="message"
              validate={required()}
              className={classes.inputField}
            />
          </Grid>
        </div>
      </Grid>

      <Grid
        style={{ width: "100%", paddingBottom: "50px" }}
        container
        spacing={10}
      >
        <Grid
          style={{ width: "100%", paddingBottom: "50px" }}
          className={classes.ptb}
          item
          xl={12}
          md={12}
          sm={12}
        ></Grid>
      </Grid>
    </Box>,
  ];
};
