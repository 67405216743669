import * as React from "react";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Link as Links } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/images/logonew-white.png";
import CssBaseline from "@material-ui/core/CssBaseline";
import backIcon from "../../assets/images/backIcon.png";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import {
  useRefresh,
  useNotify,
  Notification,
  defaultTheme,
  useRedirect,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiPaper-root": {
      position: "relative",
    },
  },
  image: {
    backgroundColor: "#09102A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    img: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "auto",
    },
  },
  paper: {
    margin: theme.spacing(8, 20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  mainHeading: {
    fontSize: "26px",
    fontWeight: 500,
  },
  createAcc: {
    marginTop: "40px",
    color: "#5B6280",
    "& a": {
      marginLeft: "10px",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    padding: "12px 20px",
    color: "#ffffff",
    cursor: "pointer",
    borderRadius: "6px",
    marginLeft: "0!important",
    fontWeight: "500!important",
    textTransform: "capitalize",
    margin: theme.spacing(2, 0, 2, 0),
    background: "linear-gradient(282.4deg, #5A35B6 15.49%, #01D1D1 114.52%)",
    "&:hover": {
      color: "#ffffff",
    },
  },
  copyright: {
    position: "absolute",
    left: "50%",
    bottom: "5%",
    color: "#7F8AB3",
    textAlign: "center",
    transform: "translateX(-50%)",
  },
  cssLabel: {
    color: "#202020 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#09102A !important",
    },
  },
  cssFocused: {
    cssLabel: {
      color: "#ffffff !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    color: "#ffffff",
    borderColor: "#202020 !important",
  },
  w_100: {
    width: "100%",
  },
}));

const Copyright = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.copyright}>
      {"Copyright © "}
      Luminary Analytics.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const submit = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/user/forgotPassword`, {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then((response) => response.json())
      .then((response) => {
        notify(response.message);
        refresh();
        if (response.status) {
          redirect("/");
        }
      });
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.centerdiv}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Box className="heading__wrapper">
              <Links to={"/login"}>
                <img src={backIcon} alt="back" />
              </Links>
              <Typography variant="h5" className={classes.mainHeading}>
                Forgot password
              </Typography>
            </Box>

            <form className={classes.form} noValidate>
              <TextField
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <TextField style={{ visibility: "hidden" }} />
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                onClick={submit}
                style={{ marginTop: "0" }}
                className={classes.submit}
              >
                Send Reset Link
              </Button>

              <div className={classes.createAcc}>
                <Typography variant="body2" align="center">
                  Not member yet?
                  <Links to={"/signup"} className="gradient-text">
                    Create an account
                  </Links>
                </Typography>
              </div>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>

        <Grid item xs={false} sm={12} md={6} className={classes.image}>
          <img src={Logo} alt="logo" className={classes.w_100} />
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};
