import {
  Show,
  FormTab,
  FileInput,
  TopToolbar,
  TabbedForm,
  useAuthenticated,
} from "react-admin";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  createStyles,
} from "@material-ui/core";
import DocIcon from "../../../assets/images/docIcon.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    subHeading: {
      fontWeight: 500,
      fontSize: "22px",
      color: "#5B6280!important",
      paddingLeft: "15px",
    },
    ptb: {
      paddingTop: "15px!important",
      paddingBottom: "0!important",
      paddingLeft: "15px!important",
      paddingRight: "15px!important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
    inputField: {
      width: "100%",
      borderRadius: "6px",
    },
  })
);

const PostEditActions = () => {
  return <TopToolbar />;
};

const Preview = ({ record }) => {
  return (
    <div className="uploaded-doc">
      <a href={record.file_url} download>
        <img src={DocIcon} alt="Document" />
        {record?.rawFile?.name || record?.file_name}
      </a>
    </div>
  );
};

export const DocumentsShow = (props) => {
  useAuthenticated();
  const classes = useStyles();

  return (
    <Show
      title="Show Documents"
      className="show-document"
      actions={<PostEditActions />}
      {...props}
    >
      <TabbedForm toolbar={false} redirect={false}>
        <FormTab className="document-table" label="Attachments" {...props}>
          <Box component="section" className={`${classes.root} myforms`}>
            <Grid style={{ width: "100%", flexDirection: "column" }} container>
              <Typography variant="h6" className={classes.subHeading}>
                Documents Preview
              </Typography>

              <FileInput placeholder=" " label=" " source="url">
                <Preview />
              </FileInput>
            </Grid>
          </Box>
        </FormTab>
      </TabbedForm>
    </Show>
  );
};
