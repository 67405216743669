import { Filter, DateInput, TextInput } from "react-admin";

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
    <DateInput label="Joined After" source="date_gte" />
    <DateInput label="Joined Before" source="date_lte" />
  </Filter>
);

export default CustomFilter;
