import * as React from "react";
import { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link as Links } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Logo from "../../assets/images/logonew-white.png";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { useLogin, useNotify, Notification, defaultTheme } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiPaper-root": {
      position: "relative",
    },
  },
  image: {
    display: "flex",
    backgroundColor: "#09102A",
    justifyContent: "center",
    alignItems: "center",
    img: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "auto",
    },
  },
  paper: {
    margin: theme.spacing(8, 20),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  copyright: {
    position: "absolute",
    left: "50%",
    bottom: "5%",
    color: "#7F8AB3",
    textAlign: "center",
    transform: "translateX(-50%)",
  },
  centerdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    padding: "12px 20px",
    color: "#ffffff",
    cursor: "pointer",
    borderRadius: "6px",
    marginLeft: "0!important",
    fontWeight: "500!important",
    textTransform: "capitalize",
    margin: theme.spacing(3, 0, 2, 0),
    background: "linear-gradient(282.4deg, #5A35B6 15.49%, #01D1D1 114.52%)",
    "&:hover": {
      color: "#ffffff",
    },
  },
  cssLabel: {
    color: "#202020 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#09102A!important",
    },
  },
  cssFocused: {
    cssLabel: {
      color: "#ffffff !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    color: "#ffffff",
  },
  mainHeading: {
    fontSize: "36px",
    fontWeight: 700,
  },
  subHeading: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#5B6280",
    margin: "20px 0 30px",
  },
  createAcc: {
    marginTop: "50px",
    color: "#5B6280",
    "& a": {
      marginLeft: "10px",
    },
  },
  w_100: {
    width: "100%",
  },
}));

const Copyright = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.copyright}>
      {"Copyright © "}
      Luminary Analytics.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    login({ email, password }).catch((e) => {
      notify("Invalid email or password provided.", "warning");
    });
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.centerdiv}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Typography variant="h5" className={classes.mainHeading}>
              Welcome back!
            </Typography>

            <Typography variant="h5" className={classes.subHeading}>
              Sign In
            </Typography>

            <form className={classes.form} noValidate>
              <TextField
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <TextField
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                onClick={submit}
                className={classes.submit}
              >
                Sign In
              </Button>

              <div className="options-wrapper">
                <div className="tab-settings">
                  <form>
                    <div className="form-group">
                      <input type="checkbox" />
                      <label htmlFor="remember">Remember me</label>
                    </div>
                  </form>
                </div>

                <Typography
                  variant="body2"
                  align="right"
                  className="gradient-text"
                  style={{ cursor: "pointer" }}
                >
                  <Links to={"/user/forgotpassword"}>Forgot Password?</Links>
                </Typography>
              </div>

              <div className={classes.createAcc}>
                <Typography variant="body2" align="center">
                  Not member yet?
                  <Links to={"/signup"} className="gradient-text">
                    Create an account
                  </Links>
                </Typography>
              </div>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={12} md={6} className={classes.image}>
          <img src={Logo} alt="logo" className={classes.w_100} />
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};
