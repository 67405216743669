import { useContext } from "react";
import { useAuthenticated, useNotify, Confirm, useRedirect } from "react-admin";
import ChatUserContext from "../../../context/ChatUserContext";

export default function DeleteGroupModal({
  deleteModalOpen,
  setDeleteModalOpen,
  selectedUserId,
}) {
  useAuthenticated();
  const notify = useNotify();
  const redirect = useRedirect();
  const token = localStorage.getItem("auth");
  const { getGroupNames } = useContext(ChatUserContext);

  const toggleClick = (state = !deleteModalOpen) => {
    setDeleteModalOpen(state);
  };

  const deleteGroup = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/group/deleteGroup/${selectedUserId}`,
      {
        method: "DELETE",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    ).then(() => {
      notify(`Group deleted successfully.`, { type: "success" });
      redirect("/");
      fetch(`${process.env.REACT_APP_API_URL}/group/getAllGroups`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          getGroupNames(response);
        });
    });
  };

  return (
    <Confirm
      key={`confirmation-popup`}
      isOpen={deleteModalOpen}
      title="Are You Sure?"
      content="Do you really want to delete this group? This process cannot be undone."
      onConfirm={deleteGroup}
      onClose={() => toggleClick(false)}
    />
  );
}
