import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { GrFormClose } from "react-icons/gr";

const NotificationPopup = ({ notifyPopup, setNotifyPopup }) => {
  const ref = useRef(null);
  const token = localStorage.getItem("auth");
  const [notifyData, setNotifyData] = useState({ notifications: [], count: 0 });

  useEffect(() => {
    const div = ref.current;
    if (notifyPopup) {
      div?.classList.add("active");
      document.body.style.overflow = "hidden";

      fetch(
        `${process.env.REACT_APP_API_URL}/notification/readAllNotification`,
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      );

      fetch(`${process.env.REACT_APP_API_URL}/notification/getNotification`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setNotifyData(response);
        });
    }

    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyPopup]);

  return (
    <>
      <div ref={ref} className="sidebar">
        <div className="bar-header">
          <div className="main-heading">
            <h4>Notifications</h4>
          </div>
          <div
            onClick={() => setNotifyPopup(false)}
            className="close-sidebarMenu"
          >
            <GrFormClose size={30} />
          </div>
        </div>

        {notifyData.notifications.map((elem) => (
          <div className="user-message-box" key={elem?.id}>
            <img src={elem?.image} alt="Avatar" />
            <div className="w-85">
              <div className="user_name">
                <h5>{elem?.notification_header}</h5>
                <span>{moment(elem?.createdAt).format("DD-MM-YYYY")}</span>
              </div>
              <p>{elem?.notification_body}</p>
            </div>
          </div>
        ))}
      </div>
      {notifyPopup && <div className="bg-overlay-fixed"></div>}
    </>
  );
};

export default NotificationPopup;
