import React, { useEffect, useState, useContext } from "react";
import { useRedirect, useNotify } from "react-admin";
import axios from "axios";
import Loader from "../General/views/Loader";
import Checked from "../../assets/images/checked.png";
import MessageContext from "../../context/MessageContext";
import NotChecked from "../../assets/images/un-checked.png";

const Pricing = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const token = localStorage.getItem("auth");
  const { loader, setLoader } = useContext(MessageContext);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [pricingData, setPricingData] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState({
    subscription: undefined,
    lookup_key: undefined,
  });

  axios.defaults.headers.common = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/subscription/getSubscriptionPlans`,
        {
          data: {},
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status) {
          setPricingData(response.data.data);
        }
      });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/subscription/getUserSubscribedPlan`,
        {
          data: {},
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status) {
          setLoader(false);
          setSubscriptionPlan({
            subscription: response.data.subscription,
            lookup_key: response.data.lookup_key,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBuyPlan = () => {
    if (selectedPlan) {
      setLoader(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/subscription/getSessionUrl`, {
          lookupKey: selectedPlan,
        })
        .then((response) => {
          if (response.status) {
            setLoader(false);
            redirect(response.data.data.session_url);
          }
        });
    } else {
      setLoader(false);
      notify(`Please select a plan.`, { type: "warning" });
    }
  };

  const handleManagePlan = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/subscription/getStripeDashboardUrl`
      )
      .then((response) => {
        if (response.status) {
          setLoader(false);
          redirect(response.data.data.dashboardUrl);
        } else {
          setLoader(false);
        }
      });
  };

  return (
    <>
      <Loader loader={loader} />
      <section className="pricing-sec" id="pricing">
        <div className="main-title justify-center">
          <h2>Pricing Plans</h2>
          {!subscriptionPlan.subscription && (
            <p>
              Currently you are on Free Trial please subscribe to Corporate plan
              to enjoy premium service
            </p>
          )}
        </div>

        <div className="row justify-evenly">
          {pricingData.map((elem, index) => {
            return (
              <div
                key={elem?.id}
                className={
                  selectedPlan === elem?.plan_type
                    ? "pricing-card active"
                    : "pricing-card"
                }
              >
                <h4 className="pricing-card-heading">{elem?.plan_type}</h4>
                <div className="pricing-amount">
                  <h3>
                    <strong>$</strong>
                    {elem?.price.substring(0, elem?.price.indexOf("."))}
                    <span>
                      {elem?.price.substring(5, elem?.price.indexOf("."))}
                    </span>
                  </h3>

                  <p>/month per location</p>
                </div>
                <ul className="pricing-features">
                  {elem.modules.map((module, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={module.value ? Checked : NotChecked}
                          alt="Check"
                        />
                        <span>{module?.name}</span>
                      </li>
                    );
                  })}
                </ul>
                {!subscriptionPlan.subscription && (
                  <div className="text-center">
                    <button
                      className="btn-gradient"
                      onClick={() => setSelectedPlan(elem?.plan_type)}
                    >
                      Choose plan
                    </button>
                  </div>
                )}
                {subscriptionPlan.lookup_key === elem?.plan_type && (
                  <div class="ribbon ribbon-top-right">
                    <span>Selected</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="pricing-buttons">
          {subscriptionPlan.subscription ? (
            <button className="btn-gradient" onClick={handleManagePlan}>
              Manage Subscription
            </button>
          ) : (
            <button className="btn-gradient" onClick={handleBuyPlan}>
              Buy Subscription
            </button>
          )}
        </div>
      </section>
    </>
  );
};

const PricingResource = {
  Plans: Pricing,
};

export default PricingResource;
