import React from "react";

const MsgDropdown = ({
  deleteMessageHandler,
  setShowPostMenu,
  showPostMenu,
  setOpenEdit,
  setEditMsg,
  msg,
}) => {
  return (
    <div className="userdropdown single-message">
      <div className="dropdown copybtn align-items-center">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() =>
            setShowPostMenu({
              showValue: !showPostMenu.showValue,
              jobId: msg?.message_id,
            })
          }
        >
          <circle cx="12.25" cy="7.25" r="1.25" fill="#09102A" />
          <circle cx="12.25" cy="12.25" r="1.25" fill="#09102A" />
          <circle cx="12.25" cy="17.25" r="1.25" fill="#09102A" />
        </svg>
        <div
          className={`dropdown-menu dropdown-menu-right ${
            showPostMenu.showValue && showPostMenu.jobId === msg?.message_id
              ? "show"
              : null
          }`}
        >
          <div
            className="dropdown-item sharelink"
            onClick={() => {
              setOpenEdit(true);
              setEditMsg({
                messageId: msg?.message_id,
                getMessage: msg?.text,
              });
              setShowPostMenu({
                ...showPostMenu,
                showValue: false,
              });
            }}
          >
            Edit Message
          </div>
          <div
            className="dropdown-item sharelink"
            onClick={() => {
              deleteMessageHandler(msg?.message_id);
              setShowPostMenu({
                ...showPostMenu,
                showValue: false,
              });
            }}
          >
            Delete Message
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgDropdown;
