import { StaffList } from "./List/Index";
import { StaffEdit } from "./Edit/Index";


const MindBodyResource = {
  Edit: StaffEdit,
  List: StaffList,
};

export default MindBodyResource;
