import * as React from "react";
import { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link as Links } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Logo from "../../assets/images/logonew-white.png";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import {
  useNotify,
  useRefresh,
  useRedirect,
  Notification,
  defaultTheme,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiPaper-root": {
      position: "relative",
    },
  },
  image: {
    display: "flex",
    backgroundColor: "#09102A",
    justifyContent: "center",
    alignItems: "center",
    img: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "auto",
    },
  },
  paper: {
    margin: theme.spacing(8, 10),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  copyright: {
    position: "absolute",
    left: "50%",
    bottom: "5%",
    color: "#7F8AB3",
    textAlign: "center",
    transform: "translateX(-50%)",
  },
  centerdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    padding: "12px 20px",
    color: "#ffffff",
    cursor: "pointer",
    borderRadius: "6px",
    fontWeight: "500!important",
    textTransform: "capitalize",
    margin: theme.spacing(3, 0, 2, 0),
    background: "linear-gradient(282.4deg, #5A35B6 15.49%, #01D1D1 114.52%)",
    "&:hover": {
      color: "#ffffff",
    },
  },
  cssLabel: {
    color: "#202020 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#09102A!important",
    },
  },
  cssFocused: {
    cssLabel: {
      color: "#ffffff !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    color: "#ffffff",
    borderColor: "#202020 !important",
  },
  mainHeading: {
    fontSize: "36px",
    fontWeight: 700,
  },
  subHeading: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#5B6280",
    margin: "20px 0 30px",
  },
  createAcc: {
    marginTop: "30px",
    color: "#5B6280",
    "& a": {
      marginLeft: "10px",
    },
  },
  w_100: {
    width: "100%",
  },
  ptb: {
    paddingLeft: "10px!important",
    paddingRight: "10px!important",
    paddingBottom: "0!important",
  },
}));

const Copyright = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.copyright}>
      {"Copyright © "}
      Luminary Analytics.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const Signup = ({ theme }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [user_name, setUserName] = useState("");
  const [full_name, setFullName] = useState("");
  const [postal_code, setPostalCaode] = useState("");
  const [module_settings, setModuleSettings] = useState({
    documents: false,
    direct_chat: false,
    group_chat: false,
    staff_management: false,
    customer_reporting: false,
    membership_reporting: false,
  });

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const submit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/user/signUp`, {
      method: "POST",
      body: JSON.stringify({
        email,
        user_name,
        full_name,
        postal_code,
        phone,
        module_settings,
      }),
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then((response) => response.json())
      .then((response) => {
        notify(response.message);
        refresh();
        if (response.status) {
          redirect("/");
        }
      });
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={6} className={classes.image}>
          <img src={Logo} alt="logo" className={classes.w_100} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.centerdiv}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Typography variant="h5" className={classes.mainHeading}>
              Welcome to FranNav!
            </Typography>

            <Typography variant="h5" className={classes.subHeading}>
              Sign Up
            </Typography>

            <form className={classes.form} noValidate>
              <Grid style={{ width: "auto" }} container spacing={15}>
                <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
                  <TextField
                    id="userName"
                    onChange={(e) => setUserName(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="User Name"
                    name="userName"
                    autoComplete="userName"
                  />
                </Grid>

                <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
                  <TextField
                    id="fullName"
                    onChange={(e) => setFullName(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    autoComplete="fullName"
                  />
                </Grid>

                <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
                  <TextField
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                  />
                </Grid>

                <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
                  <TextField
                    id="postalCode"
                    onChange={(e) => setPostalCaode(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Postal Code"
                    name="postalCode"
                    autoComplete="postalCode"
                  />
                </Grid>

                <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
                  <TextField
                    id="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Phone"
                    name="phone"
                    autoComplete="phone"
                  />
                </Grid>
              </Grid>

              <div className="tab-settings">
                <form className="mx-2">
                  <Typography
                    variant="h5"
                    align="left"
                    className={classes.subHeading}
                    style={{ marginBottom: "20px" }}
                  >
                    Select Modules
                  </Typography>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <input
                        type="checkbox"
                        checked={module_settings.documents}
                        onChange={() => {
                          setModuleSettings({
                            ...module_settings,
                            documents: !module_settings.documents,
                          });
                        }}
                      />
                      <label htmlFor="documents">Documents</label>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="checkbox"
                        checked={module_settings.direct_chat}
                        onChange={() =>
                          setModuleSettings({
                            ...module_settings,
                            direct_chat: !module_settings.direct_chat,
                          })
                        }
                      />
                      <label htmlFor="direct_chat">Direct Chat</label>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="checkbox"
                        checked={module_settings.group_chat}
                        onChange={() =>
                          setModuleSettings({
                            ...module_settings,
                            group_chat: !module_settings.group_chat,
                          })
                        }
                      />
                      <label htmlFor="group_chat">Group Chat</label>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="checkbox"
                        checked={module_settings.customer_reporting}
                        onChange={() =>
                          setModuleSettings({
                            ...module_settings,
                            customer_reporting:
                              !module_settings.customer_reporting,
                          })
                        }
                      />
                      <label htmlFor="customer_reporting">
                        Customer Reporting
                      </label>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="checkbox"
                        checked={module_settings.membership_reporting}
                        onChange={() =>
                          setModuleSettings({
                            ...module_settings,
                            membership_reporting:
                              !module_settings.membership_reporting,
                          })
                        }
                      />
                      <label htmlFor="membership_reporting">
                        MemberShip Reporting
                      </label>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="checkbox"
                        checked={module_settings.staff_management}
                        onChange={() =>
                          setModuleSettings({
                            ...module_settings,
                            staff_management: !module_settings.staff_management,
                          })
                        }
                      />
                      <label htmlFor="membership_reporting">
                        Staff Management
                      </label>
                    </div>
                  </div>
                </form>
              </div>

              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                onClick={submit}
                className={classes.submit}
              >
                Start 14 days free trail
              </Button>

              <div className={classes.createAcc}>
                <Typography variant="body2" align="center">
                  Already have an account?
                  <Links to={"/login"} className="gradient-text">
                    Sign In
                  </Links>
                </Typography>
              </div>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};
