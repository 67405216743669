import { TextInput, SelectInput, required } from "react-admin";
import { Box, Grid, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    accordion_details: {
      display: "block",
      padding: "0",
      borderBottom: "0 !important",
      "& .MuiAccordionSummary-root": {
        padding: "0",
        maxWidth: "150px",
      },
    },
    colorTextgrey: {
      color: "#ffffff",
    },
    ptb: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
    root: {
      "& .MuiSelect-root, .MuiInputBase-input": {
        paddingLeft: "0",
        paddingRight: "0",
        marginTop: "10px",
        color: "#ffffff",
      },
      "& .MuiFilledInput-underline": {
        "&:before": {
          borderBottom: "1px solid #ffffff",
        },
      },
    },
  })
);

export const BasicDetails = (props) => {
  const classes = useStyles();

  return [
    <Box
      component="section"
      style={{ paddingTop: "80px" }}
      className={`${classes.root} myforms`}
    >
      <Grid style={{ width: "100%" }} container spacing={10}>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="first_name" validate={required()} />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="last_name" validate={required()} />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="email" validate={required()} />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="location" validate={required()} />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput
            fullWidth
            source="membership_price"
            validate={required()}
          />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <SelectInput
            fullWidth
            source={"status"}
            choices={[
              { id: "active", name: "Active" },
              { id: "frozen", name: "Frozen" },
              { id: "renewing", name: "Renewing" },
              { id: "payment failure", name: "Payment Failure" },
            ]}
          />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="phone" validate={required()} />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={10}
        style={{ width: "100%", paddingBottom: "50px" }}
      ></Grid>
    </Box>,
  ];
};
