import { useState, useEffect, useContext } from "react";
import Menu from "./Menu";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import { RiSearchLine } from "react-icons/ri";
import { FiChevronDown } from "react-icons/fi";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import UsersContext from "../../context/UsersContext";
import MessageContext from "../../context/MessageContext";
import ChatUserContext from "../../context/ChatUserContext";
import notifyIcon from "../../assets/images/notifyIcon.png";
import unNotifyIcon from "../../assets/images/unNotifyIcon.png";
import NotificationPopup from "../General/utils/NotificationPopup";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
// import logonew from "../../assets/images/logonew.png";

import Loader from "../General/views/Loader";
import {
  useRedirect,
  Layout as RALayout,
  setSidebarVisibility,
} from "react-admin";

export const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 32,
    color: "#fff",
    padding: "25px 0 0 0",
  },
  spacer: {
    flex: 1,
  },
});

const Layout = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");
  const fullName = localStorage.getItem("full_name");
  const profileImg = localStorage.getItem("profile_image");
  const [loader, setLoader] = useState(true);
  const [notifyPopup, setNotifyPopup] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const [showPostMenu, setShowPostMenu] = useState({
    showValue: false,
  });
  const { sidebarToggle, setSidebarToggle, setShowSideTabs, setAllActiveTabs } =
    useContext(UsersContext);
  const { getGroupNames, setDeleteListGroup, setUnReadMsgCount } =
    useContext(ChatUserContext);
  const { setSocketMsg, setSingleSocketMsg, setDeleteMsg, setEditMessage } =
    useContext(MessageContext);
  const leaveChatRoom = useSelector(
    (state) => state?.router?.location?.pathname
  ).slice(0, 10);

  useEffect(() => {
    if (token && role !== "Super Admin") {
      fetch(`${process.env.REACT_APP_API_URL}/settings/getSetting`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setShowSideTabs(response.data);
          setAllActiveTabs({
            documents: response.data.documents,
            group_chat: response.data.group_chat,
            direct_chat: response.data.direct_chat,
            membership_reporting: response.data.membership_reporting,
            customer_reporting: response.data.customer_reporting,
            staff_management: response.data.staff_management,
          });
        });

      fetch(
        `${process.env.REACT_APP_API_URL}/report/getTableArrangement?reportType=customer`,
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          let data = JSON.stringify({
            undefined: {
              email: response.email,
              first_name: response.first_name,
              joining_date: response.joining_date,
              joining_source: response.joining_source,
              last_name: response.last_name,
              location: response.location,
              phone: response.phone,
              serial_no: 1,
            },
          });
          localStorage.setItem("raColumnsConfig", data);
        });
    }

    if (role) {
      fetch(
        `${process.env.REACT_APP_API_URL}/notification/getUnReadNotification`,
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setNewNotification(response.countUpdated);
        });
    }

    socket.emit("addUser", {
      userId: localStorage.getItem("id"),
    });

    socket.on("new_message", (data) => {
      setSocketMsg(data);
    });

    socket.on("single_message", (data) => {
      setSingleSocketMsg(data);
    });

    socket.on("delete_message", (data) => {
      setDeleteMsg(data);
    });

    socket.on("edit_message", (data) => {
      setEditMessage(data);
    });

    socket.on("new_group", (data) => {
      getGroupNames((current) => [...current, data.msg]);
    });

    socket.on("delete_group", (data) => {
      setDeleteListGroup(data.msg);
    });

    socket.on("listenMessageCount", (data) => {
      setUnReadMsgCount(data);
    });

    socket.on("newNotification", (data) => {
      setNewNotification(data.countUpdated);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (leaveChatRoom !== "/groupChat") {
      socket.emit("leaveGroup", {
        userId: localStorage.getItem("id"),
      });
    }
    if (leaveChatRoom !== "/messages/") {
      socket.emit("leaveChatScreen", {
        userId: localStorage.getItem("id"),
      });
    }
  }, [leaveChatRoom]);

  useEffect(() => {
    dispatch(setSidebarVisibility(sidebarToggle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarToggle]);

  useEffect(() => {
    if (role) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <RALayout
      style={{ backgroundColor: "#181c1f", padding: 0 }}
      {...props}
      menu={Menu}
      className="ra-layout"
    >
      <Loader loader={loader} />
      <Container style={{ margin: 0, padding: 0 }}>
        <Typography
          variant="h1"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <nav className="navbar">
          <div
            className={` v-hidden logo-wrapper ${
              !sidebarToggle && "closedSidebar"
            }`}
          >
            {/* {sidebarToggle && <img src={logonew} alt="" />} */}
            <i onClick={() => setSidebarToggle(!sidebarToggle)}>
              {sidebarToggle ? (
                <FiChevronLeft size={20} />
              ) : (
                <FiChevronRight size={20} />
              )}
            </i>
          </div>

          <div className="form-group v-hidden">
            <RiSearchLine className="searchIcon" />
            <input
              type="text"
              className="form-control"
              placeholder="Search anything"
            />
          </div>

          <div className="userdropdown profile-dropdown">
            <div className="dropdown copybtn align-items-center">
              <div className="d-flex">
                <img
                  src={newNotification ? notifyIcon : unNotifyIcon}
                  alt="BellIcon"
                  className="notify-badge"
                  onClick={() => setNotifyPopup(true)}
                />

                <div
                  className="profile-badge"
                  onClick={() =>
                    setShowPostMenu({
                      showValue: !showPostMenu.showValue,
                    })
                  }
                >
                  <img src={profileImg} alt="Avatar" />
                  <div>
                    <h4>{fullName}</h4>
                    <p>{role}</p>
                  </div>
                  <i>
                    <FiChevronDown />
                  </i>
                </div>
              </div>

              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showPostMenu.showValue ? "show" : null
                }`}
              >
                <div
                  className="dropdown-item sharelink"
                  onClick={() => {
                    redirect("/my-profile");
                    setShowPostMenu({
                      ...showPostMenu,
                      showValue: false,
                    });
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse
                      cx="12"
                      cy="17.5"
                      rx="7"
                      ry="3.5"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="12"
                      cy="7"
                      r="4"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                  My Profile
                </div>
                <div
                  className="dropdown-item sharelink"
                  onClick={() => {
                    localStorage.clear();
                    redirect("/login");
                    setShowPostMenu({
                      ...showPostMenu,
                      showValue: false,
                    });
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 14L19.2929 12.7071C19.6834 12.3166 19.6834 11.6834 19.2929 11.2929L18 10"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 12L13 12M4 17.2663V7.26633M16 17.2663C16 18.3709 15.1046 19.2663 14 19.2663H10M16 7.26633C16 6.16176 15.1046 5.26633 14 5.26633H10M4.8906 19.8601L6.8906 21.1934C8.21971 22.0795 10 21.1267 10 19.5293V5.00336C10 3.40597 8.21971 2.45319 6.8906 3.33926L4.8906 4.6726C4.3342 5.04353 4 5.66799 4 6.3367V18.196C4 18.8647 4.3342 19.4891 4.8906 19.8601Z"
                      stroke="#09102A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  Logout
                </div>
              </div>
            </div>
          </div>
        </nav>

        {props.children}
      </Container>
      <NotificationPopup
        notifyPopup={notifyPopup}
        setNotifyPopup={setNotifyPopup}
      />
    </RALayout>
  );
};

export default Layout;
