import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  createStyles,
} from "@material-ui/core";
import DocIcon from "../../../assets/images/docIcon.png";
import UploadImage from "../../../assets/images/uploadimage.jpg";
import {
  FormTab,
  required,
  TextInput,
  FileInput,
  SelectInput,
  AutocompleteArrayInput,
} from "react-admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    subHeading: {
      fontWeight: 500,
      fontSize: "22px",
      marginBottom: "30px",
      color: "#5B6280!important",
      paddingLeft: "15px",
    },
    ptb: {
      paddingTop: "15px!important",
      paddingBottom: "0!important",
      paddingLeft: "15px!important",
      paddingRight: "15px!important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
    inputField: {
      width: "100%",
      borderRadius: "6px",
    },
  })
);

const Placeholder = (props) => {
  return <img src={UploadImage} alt="Upload Document" />;
};

const Preview = ({ record }) => {
  return (
    <div className="uploaded-doc">
      <a href={record.file_url} download>
        <img src={DocIcon} alt="Document" />
        {record?.rawFile?.name || record?.file_name}
      </a>
    </div>
  );
};

export const Attachments = (props) => {
  const classes = useStyles();
  const token = localStorage.getItem("auth");
  const [addLocations, setAddLocations] = useState([]);
  const [addDropdownValue, setAddDropdownValue] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/location/getAllLocations`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setAddLocations(response);
      });

    fetch(
      `${process.env.REACT_APP_API_URL}/documentTypeManagement/getAllDocumentsType`,
      {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setAddDropdownValue(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addLocationChoices = () => {
    if (!addLocations) return [];
    return (addLocations ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  const addDropdownChoices = () => {
    if (!addDropdownValue) return [];
    return (addDropdownValue ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  return (
    <FormTab className="document-table" label="Attachments" {...props}>
      <Box component="section" className={`${classes.root} myforms`}>
        <Grid style={{ width: "100%", flexDirection: "column" }} container>
          <Typography variant="h6" className={classes.subHeading}>
            Edit Document Details
          </Typography>

          <div className="row">
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput
                fullWidth
                source="name"
                validate={required()}
                className={classes.inputField}
              />
            </Grid>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <SelectInput
                fullWidth
                label="Document Type"
                source={"doc_type"}
                className={classes.inputField}
                choices={addDropdownChoices()}
              />
            </Grid>
            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={4}
              md={6}
              sm={6}
            >
              <AutocompleteArrayInput
                fullWidth
                label="Access Level"
                source={"access_level"}
                className={classes.inputField}
                choices={[
                  { id: "Owner", name: "Owner" },
                  { id: "Staff", name: "Staff" },
                ]}
              />
            </Grid>
            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={4}
              md={6}
              sm={6}
            >
              <AutocompleteArrayInput
                fullWidth
                label="Locations"
                source={"locations"}
                className={classes.inputField}
                choices={addLocationChoices()}
              />
            </Grid>
          </div>
        </Grid>

        <FileInput
          container
          validate={required()}
          placeholder={<Placeholder />}
          label=" "
          source="url"
          accept=".xlsx, .csv, .pdf, .doc, .docx, .ppt, .pptx, rtf"
          multiple={true}
          className="all-documents"
        >
          <Preview />
        </FileInput>
      </Box>
    </FormTab>
  );
};
