import { MyProfile } from "./MyProfile/Index";
import { EditAdmin } from "./Edit/Index";
import { CreateAdmin } from "./Create/Index";

const CustomerResource = {
  MyProfile: MyProfile,
  Edit: EditAdmin,
  Create: CreateAdmin,
};

export default CustomerResource;
