import { useState } from "react";
import { BasicDetails } from "./BasicDetails";
import { EditTabs } from "../../../General/utils/EditTabs";
import { Edit, useAuthenticated, FormWithRedirect } from "react-admin";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { EditBottomToolBar } from "../../../General/utils/EditBottomToolBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

export const MemberShipEdit = (props) => {
  useAuthenticated();
  const classes = useStyles();
  const tabsWithDetails = {
    basic_details: {
      name: "Edit MemberShip Details",
      component: <BasicDetails />,
    },
  };
  const tabIds = Object.keys(tabsWithDetails);
  const [currentTabId, setCurrentTabId] = useState(tabIds[0]);

  return (
    <Edit
      className={classes.root}
      title="MemberShip Details"
      mutationMode="pessimistic"
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={(formProps) => [
          <EditTabs
            key="tabs"
            currentTabId={currentTabId}
            tabsWithDetails={tabsWithDetails}
            setCurrentTabId={setCurrentTabId}
          />,
          <Container key="content">
            {tabsWithDetails[currentTabId].component}
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
