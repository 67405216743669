import { useState, useEffect, forwardRef } from "react";
import {
  AutocompleteArrayInput,
  useAuthenticated,
  SelectInput,
  SimpleForm,
  useRefresh,
  FileInput,
  TextInput,
  useNotify,
  required,
  Create,
} from "react-admin";
import { MdClose } from "react-icons/md";
import { Grid } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DocIcon from "../../../assets/images/docIcon.png";
import { createStyles, makeStyles } from "@material-ui/core";
import UploadImage from "../../../assets/images/uploadimage.jpg";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "40px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    fileUpload: {
      "& label": {
        display: "none",
      },
    },
  })
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Placeholder = () => {
  return <img src={UploadImage} className="w-100" alt="Upload Document" />;
};

const Preview = ({ record }) => {
  const recordKeys = Object.keys(record);
  const src = recordKeys.length ? record[recordKeys[1]] : null;

  return (
    <div className="uploaded-doc">
      <a href={src} download>
        <img src={DocIcon} alt="Document" />
        {record.rawFile.name}
      </a>
    </div>
  );
};

export default function CreateDocumentModal({ open, setOpen, props }) {
  useAuthenticated();
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");
  const [addLocations, setAddLocations] = useState([]);
  const [addDropdownValue, setAddDropdownValue] = useState([]);

  useEffect(() => {
    if (!open) {
      refresh();
    }

    fetch(`${process.env.REACT_APP_API_URL}/location/getAllLocations`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setAddLocations(response);
      });

    if (role === "Client") {
      fetch(
        `${process.env.REACT_APP_API_URL}/documentTypeManagement/getAllDocumentsType`,
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setAddDropdownValue(response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    setOpen(false);
    notify(`Created successfully.`, { type: "success" });
  };

  const onFailure = (data) => {
    notify(data.message, { type: "warning" });
  };

  const addLocationChoices = () => {
    if (!addLocations) return [];
    return (addLocations ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  const addDropdownChoices = () => {
    if (!addDropdownValue) return [];
    return (addDropdownValue ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      className="addUserModal"
      TransitionComponent={Transition}
    >
      <h2>
        Create Document
        <i onClick={() => setOpen(false)}>
          <MdClose size={28} />
        </i>
      </h2>
      <Create
        className="mybg"
        title=" "
        redirect={false}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onFailure}
        {...props}
      >
        <SimpleForm className={classes.SimpleForm}>
          <Grid style={{ width: "auto" }} container spacing={10}>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput fullWidth source="name" validate={required()} />
            </Grid>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <SelectInput
                fullWidth
                label="Document Type"
                source={"doc_type"}
                choices={addDropdownChoices()}
              />
            </Grid>
            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={4}
              md={6}
              sm={6}
            >
              <AutocompleteArrayInput
                fullWidth
                label="Locations"
                source="locations"
                validate={required()}
                choices={addLocationChoices()}
              />
            </Grid>
            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={4}
              md={6}
              sm={6}
            >
              <AutocompleteArrayInput
                fullWidth
                label="Access Level"
                source="access_level"
                choices={[
                  { id: "Owner", name: "Owner" },
                  { id: "Staff", name: "Staff" },
                ]}
              />
            </Grid>
            <Grid className={classes.ptb} item xl={12} md={12} sm={12}>
              <FileInput
                validate={required()}
                label=" "
                source="document"
                multiple={true}
                className={classes.fileUpload}
                placeholder={<Placeholder />}
                accept=".xlsx, .csv, .pdf, .doc, .docx, .ppt, .pptx, rtf"
              >
                <Preview />
              </FileInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
