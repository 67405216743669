import { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";
import MsgInput from "./MsgInput";
import ChatHeader from "./ChatHeader";
import MsgDropdown from "./MsgDropdown";
import { groupBy, mapValues } from "lodash";
import { socket } from "../../Layout/Index";
import GroupInfoMenu from "./GroupInfoMenu";
import { BsCheck2, BsX } from "react-icons/bs";
import Loader from "../../General/views/Loader";
import GroupChatSidebar from "./GroupChatSidebar";
import AddMembersModal from "../Create/AddMembersModal";
import DeleteGroupModal from "../List/DeleteGroupModel";
import CreateGroupModal from "../Create/CreateGroupModal";
import { useAuthenticated, useNotify } from "react-admin";
import MessageContext from "../../../context/MessageContext";
import ChatUserContext from "../../../context/ChatUserContext";
import { FaFileCsv, FaFilePdf, FaDochub, FaFileExcel } from "react-icons/fa";

export const GroupChatList = (props) => {
  useAuthenticated();
  const notify = useNotify();
  const messagesEndRef = useRef(null);
  const token = localStorage.getItem("auth");
  const groupCreatorId = localStorage.getItem("id");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [showGroupMembers, setShowGroupMembers] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editMsg, setEditMsg] = useState({ getMessage: "", messageId: "" });
  const [showPostMenu, setShowPostMenu] = useState({
    showValue: false,
    userId: "",
  });
  const [showMemberListMenu, setShowMemberListMenu] = useState({
    showValue: false,
    memberId: "",
  });
  const { groupNames, getGroupNames } = useContext(ChatUserContext);
  const {
    loader,
    messages,
    socketMsg,
    setLoader,
    deleteMsg,
    editMessage,
    getAllMessages,
  } = useContext(MessageContext);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setLoader(true);
    fetch(`${process.env.REACT_APP_API_URL}/group/getAllGroups`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        getGroupNames(response);
        setLoader(false);
      });

    if (selectedUserId) {
      socket.emit("joinGroup", {
        groupId: selectedUserId,
        token: token,
      });

      fetch(`${process.env.REACT_APP_API_URL}/messages/getAllMessages`, {
        method: "POST",
        body: JSON.stringify({ group_id: selectedUserId, skip: 1, limit: 10 }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          getAllMessages(response.data);
          setLoader(false);
        });

      fetch(
        `${process.env.REACT_APP_API_URL}/group/groupDetail/${selectedUserId}`,
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setShowGroupMembers(response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  useEffect(() => {
    if (!!socketMsg) {
      const newMessageDate = moment(socketMsg?.createdAt).format("MM/DD/YYYY");
      socketMsg.createdAt = newMessageDate;
      getAllMessages((current) => [...current, socketMsg]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketMsg]);

  useEffect(() => {
    if (!!editMessage) {
      const filterMessages = messages.filter((passedMsg) => {
        if (passedMsg?.message_id === editMessage?.msgId) {
          passedMsg.text = editMessage.text;
        }
        return messages;
      });
      getAllMessages(filterMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessage]);

  useEffect(() => {
    if (!!deleteMsg) {
      const filterMessages = messages.filter((passedMsg) => {
        return passedMsg?.message_id !== deleteMsg?.msgId;
      });
      getAllMessages(filterMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMsg]);

  const groupName = groupNames.find((elem) => {
    return elem?.id === selectedUserId;
  });

  const deleteMessageHandler = (msgId) => {
    if (msgId) {
      const filterMessages = messages.filter((passedMsg) => {
        return passedMsg?.message_id !== msgId;
      });
      getAllMessages(filterMessages);

      fetch(
        `${process.env.REACT_APP_API_URL}/messages/deleteMessage/${msgId}`,
        {
          method: "DELETE",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      );
    }
  };

  const editMessageHandler = (msgId) => {
    if (editMsg?.getMessage !== "") {
      const filterMessages = messages.filter((passedMsg) => {
        if (passedMsg?.message_id === msgId) {
          passedMsg.text = editMsg.getMessage;
        }
        return messages;
      });
      getAllMessages(filterMessages);

      fetch(`${process.env.REACT_APP_API_URL}/messages/editMessage/${msgId}`, {
        method: "PUT",
        body: JSON.stringify({ text: editMsg?.getMessage }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }).then(() => {
        setOpenEdit(false);
      });
    }
  };

  const deleteMemberHandler = (member_Id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/group/deleteMember/${selectedUserId}/${member_Id}`,
      {
        method: "DELETE",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        notify(response?.message, { type: "success" });
        fetch(
          `${process.env.REACT_APP_API_URL}/group/groupDetail/${selectedUserId}`,
          {
            method: "GET",
            headers: new Headers({
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            setShowGroupMembers(response);
          });
      });
  };

  const docsIcon = (docType) => {
    const docsMappings = {
      "text/csv": <FaFileCsv />,
      "application/pdf": <FaFilePdf />,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
        <FaFileExcel />
      ),
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <FaDochub />,
    };
    return docsMappings[docType];
  };

  const groupedMessage = mapValues(groupBy(messages, "createdAt"));
  const msgToReturn = [];
  for (let focusedGroupedMsg in groupedMessage) {
    const groupMsgsArray = groupedMessage[focusedGroupedMsg];
    msgToReturn.push(
      <div className="channel__body__grouped">
        <div className="channel__body__day">
          <div className="channel__body__date">
            <span>{focusedGroupedMsg}</span>
          </div>
        </div>
      </div>
    );
    const relMsgs = groupMsgsArray.map((msg) => {
      return (
        <div className="message" key={msg?.message_id}>
          <div
            className={`${
              msg?.from === groupCreatorId
                ? "d-flex align-start w-100 message-reverse"
                : "d-flex align-start w-100 owner-message"
            }`}
          >
            <div className="message__profile">
              <img src={msg?.image_url} alt="Avatar" />
            </div>
            <div className="message__content">
              <div className="message__sender">
                <span className="name">{msg?.user_name}</span>
              </div>
              <div className="message__text__outer_div">
                <div>
                  <div className="message__text">
                    {openEdit && msg?.message_id === editMsg?.messageId ? (
                      <div className="title-text-area d-flex align-items-center justify-content-center">
                        <input
                          type="text"
                          value={editMsg?.getMessage}
                          placeholder="Type a title..."
                          className="editor-title border-0 m-0"
                          onChange={(e) =>
                            setEditMsg({
                              ...editMsg,
                              getMessage: e.target.value,
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter")
                              editMessageHandler(msg?.message_id);
                          }}
                          maxLength={70}
                        />
                        <button
                          className="edit-msg-btn px-2"
                          onClick={() => {
                            editMessageHandler(msg?.message_id);
                          }}
                        >
                          <BsCheck2 />
                        </button>

                        <button
                          className="edit-msg-btn px-2"
                          onClick={() => {
                            setOpenEdit(false);
                          }}
                        >
                          <BsX />
                        </button>
                      </div>
                    ) : (
                      <>
                        <p>{msg?.text}</p>

                        {msg?.images.length > 0 && (
                          <div className="message-images">
                            {msg?.images.map((elem, index) => {
                              return (
                                <a
                                  href={elem?.src || elem}
                                  rel="noreferrer"
                                  target="_blank"
                                  key={index}
                                >
                                  <img src={elem?.src || elem} alt="" />
                                </a>
                              );
                            })}
                          </div>
                        )}

                        {msg?.documents.length > 0 && (
                          <div className="documents-wrapper">
                            {msg?.documents.map((elem, index) => {
                              return (
                                <a
                                  href={elem?.src || elem?.file_url}
                                  key={index}
                                  download
                                >
                                  <div>
                                    <i>
                                      {docsIcon(elem?.doc_type || elem?.type)}
                                    </i>
                                    <label>
                                      {elem?.name || elem?.file_name}
                                    </label>
                                  </div>
                                </a>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <span className="time">
                    {moment(msg?.message_date).format("hh:mm A")}
                  </span>
                </div>

                {msg?.from === groupCreatorId && (
                  <MsgDropdown
                    msg={msg}
                    setEditMsg={setEditMsg}
                    setOpenEdit={setOpenEdit}
                    showPostMenu={showPostMenu}
                    setShowPostMenu={setShowPostMenu}
                    deleteMessageHandler={deleteMessageHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
    msgToReturn.push(relMsgs);
  }

  return (
    <>
      <Loader loader={loader} />
      <section className="direct-message-main">
        <GroupChatSidebar
          setModalOpen={setModalOpen}
          setSelectedUserId={setSelectedUserId}
        />

        <div
          className={`chat-modeule group-message-module ${
            !selectedUserId && "hidden"
          }`}
        >
          {!!selectedUserId && (
            <>
              <ChatHeader
                groupName={groupName}
                setDeleteModalOpen={setDeleteModalOpen}
              />
              <div className="channel__body">
                {msgToReturn.map((elem, index) => {
                  return <div key={index}>{elem}</div>;
                })}

                <div ref={messagesEndRef} />
              </div>
              <MsgInput selectedUserId={selectedUserId} docsIcon={docsIcon} />
            </>
          )}
        </div>

        {selectedUserId && (
          <GroupInfoMenu
            setOpen={setOpen}
            groupName={groupName}
            showGroupMembers={showGroupMembers}
            showMemberListMenu={showMemberListMenu}
            deleteMemberHandler={deleteMemberHandler}
            setShowMemberListMenu={setShowMemberListMenu}
          />
        )}
      </section>
      <CreateGroupModal
        props={props}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <AddMembersModal
        open={open}
        props={props}
        setOpen={setOpen}
        selectedUserId={selectedUserId}
        setShowGroupMembers={setShowGroupMembers}
      />
      <DeleteGroupModal
        selectedUserId={selectedUserId}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    </>
  );
};
