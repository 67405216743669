import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  Confirm,
  useNotify,
  useRefresh,
  useRedirect,
  useDataProvider,
} from "react-admin";

export const ListActions = ({ record, resource }) => {
  const title = "Are you sure?";
  const content =
    "Do you really want to delete these records? This process cannot be undone.";
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [showPostMenu, setShowPostMenu] = useState(false);

  const toggleClick = (state = !open) => {
    setOpen(state);
  };

  const deleteRecord = () => {
    const { id } = record;
    dataProvider
      .delete(resource, {
        id,
      })
      .then(() => {
        notify("Entity deleted successfully.", { type: "success" });
        refresh();
      })
      .catch((error) => notify("Unknown error occurred", "warning"));
  };

  return [
    <Confirm
      key={`confirmation-popup`}
      isOpen={open}
      title={title}
      content={content}
      onConfirm={deleteRecord}
      onClose={() => toggleClick(false)}
    />,

    <div className="userdropdown bg-editIcon">
      <div className="dropdown copybtn align-items-center">
        <BsThreeDots onClick={() => setShowPostMenu(!showPostMenu)} />

        <div
          className={`dropdown-menu dropdown-menu-right ${
            showPostMenu ? "show" : null
          }`}
        >
          <div
            className="dropdown-item sharelink"
            onClick={() => {
              setShowPostMenu(false);
              redirect(`/${resource}/${record.id}`);
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 17H17M10.5861 3.05486C10.5861 3.05486 10.5861 4.50786 12.0391 5.96086C13.4921 7.41386 14.9451 7.41386 14.9451 7.41386M4.83967 14.3227L7.89097 13.8868C8.33111 13.824 8.73898 13.62 9.05337 13.3056L16.3981 5.96085C17.2006 5.15838 17.2006 3.85732 16.3981 3.05485L14.9451 1.60185C14.1427 0.799383 12.8416 0.799382 12.0391 1.60185L4.69437 8.94663C4.37998 9.26102 4.17604 9.66889 4.11317 10.109L3.67727 13.1603C3.5804 13.8384 4.1616 14.4196 4.83967 14.3227Z"
                stroke="#09102A"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
            <span>{resource === "leads" ? "Details" : "Edit"}</span>
          </div>
          {resource !== "leads" && (
            <div
              className="dropdown-item sharelink"
              onClick={() => {
                setShowPostMenu(false);
                toggleClick(true);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2222 9.3L17.5859 17.6761C17.4434 19.5517 15.8987 21 14.0408 21H9.95922C8.10131 21 6.55663 19.5517 6.41414 17.6761L5.77778 9.3M20 7.5C17.6907 6.36058 14.9455 5.7 12 5.7C9.05453 5.7 6.30926 6.36058 4 7.5M10.2222 5.7V4.8C10.2222 3.80589 11.0182 3 12 3C12.9818 3 13.7778 3.80589 13.7778 4.8V5.7M10.2222 11.1V16.5M13.7778 11.1V16.5"
                  stroke="#09102A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              <span>Delete</span>
            </div>
          )}
        </div>
      </div>
    </div>,
  ];
};
