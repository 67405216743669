import { useState, useEffect, forwardRef } from "react";
import {
  AutocompleteArrayInput,
  useAuthenticated,
  SimpleForm,
  useNotify,
  TextInput,
  useRefresh,
  Create,
} from "react-admin";
import Slide from "@mui/material/Slide";
import { MdClose } from "react-icons/md";
import { Grid } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "40px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    fileUpload: {
      "& label": {
        display: "none",
      },
    },
  })
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddMembersModal({
  open,
  props,
  setOpen,
  selectedUserId,
  setShowGroupMembers,
}) {
  useAuthenticated();
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const token = localStorage.getItem("auth");
  const [addDropdownValue, setAddDropdownValue] = useState([]);

  useEffect(() => {
    if (!open) {
      refresh();
    }

    if (open) {
      fetch(
        `${process.env.REACT_APP_API_URL}/group/getNewUsersList/${selectedUserId}`,
        {
          method: "GET",
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setAddDropdownValue(response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    notify(`Member added successfully.`, { type: "success" });
    setOpen(false);
    fetch(
      `${process.env.REACT_APP_API_URL}/group/groupDetail/${selectedUserId}`,
      {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setShowGroupMembers(response);
      });
  };

  const onFailure = (data) => {
    notify(data.message, { type: "warning" });
  };

  const addDropdownChoices = () => {
    if (!addDropdownValue) return [];
    return (addDropdownValue ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.username,
      };
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      className="addUserModal"
      TransitionComponent={Transition}
    >
      <h2>
        Add New Members
        <i onClick={() => setOpen(false)}>
          <MdClose size={28} />
        </i>
      </h2>
      <Create
        className="mybg"
        title=" "
        redirect={false}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onFailure}
        {...props}
      >
        <SimpleForm className={classes.SimpleForm}>
          <Grid style={{ width: "auto" }} container spacing={10}>
            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={12}
              md={12}
              sm={12}
            >
              <AutocompleteArrayInput
                fullWidth
                label="Add Members"
                source="member_ids"
                choices={addDropdownChoices()}
              />
            </Grid>

            <TextInput
              className="hidden"
              source="group_id"
              defaultValue={selectedUserId}
            />
          </Grid>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
