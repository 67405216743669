import { createContext, useState } from "react";

const UsersContext = createContext();

export function UsersProvider({ children }) {
  const [loader, setLoader] = useState(false);
  const [showSideTabs, setShowSideTabs] = useState(null);
  const [userAvatar, setUserAvatar] = useState();
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [aciveTabs, setAciveTabs] = useState({
    documents: null,
    group_chat: null,
    direct_chat: null,
    membership_reporting: null,
    customer_reporting: null,
    staff_management: null,
  });

  const setAllActiveTabs = (data) => {
    setAciveTabs(data);
  };

  return (
    <UsersContext.Provider
      value={{
        loader,
        setLoader,
        aciveTabs,
        setAllActiveTabs,
        showSideTabs,
        setShowSideTabs,
        userAvatar,
        setUserAvatar,
        sidebarToggle,
        setSidebarToggle,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export default UsersContext;
