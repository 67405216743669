import React, { useState, useContext } from "react";
import moment from "moment";
import ChatUserContext from "../../../context/ChatUserContext";

const ChatSidebar = ({ setSelectedUserId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { members, getAllUsers } = useContext(ChatUserContext);

  const resetSingleMsgCount = (id) => {
    for (let member of members) {
      if (member.id === id) {
        member.unread_message_count = 0;
      }
    }
    getAllUsers(members);
  };

  return (
    <div className="chat-header">
      <div className="header-text">
        <h1>Direct Message</h1>
      </div>
      <div className="search-field">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 17L14.6 14.6M1 7.65001C1 3.97731 3.97731 1 7.65 1C11.3227 1 14.3 3.97731 14.3 7.65001C14.3 11.3227 11.3227 14.3 7.65 14.3C3.97731 14.3 1 11.3227 1 7.65001Z"
            stroke="#5B6280"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search message"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="owner-list">
        {members
          .filter((val) => {
            if (searchTerm === "") {
              return val;
            }
            return val.username
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          })
          .map((elem, index) => {
            return (
              <div
                key={index}
                className="owners-list-tabs"
                onClick={() => {
                  setSelectedUserId(elem?.id);
                  resetSingleMsgCount(elem?.id);
                }}
              >
                <img src={elem?.profile_image} alt="Avatar" />
                <div className="owners-list-text">
                  <h2>{elem?.username}</h2>
                  <p>{elem?.last_message_text}</p>
                </div>
                <div className="owners-list-badge">
                  <p>{moment(elem?.last_message_time).format("hh:mm A")}</p>
                  {elem?.unread_message_count > 0 && (
                    <p className="badge">{elem?.unread_message_count}</p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ChatSidebar;
