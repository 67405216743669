import { forwardRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  useAuthenticated,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRefresh,
  Create,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { MdClose } from "react-icons/md";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "30px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
  })
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignLocation({ open, setOpen, props }) {
  useAuthenticated();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (!open) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    setOpen(false);
    notify(`Location added successfully.`, { type: "success" });
  };

  const onFailure = (data) => {
    notify(data.message, { type: "warning" });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      className="addUserModal"
      TransitionComponent={Transition}
    >
      <h2>
        Assign Location
        <i onClick={() => setOpen(false)}>
          <MdClose size={28} />
        </i>
      </h2>
      <Create
        className="mybg"
        title=" "
        redirect={false}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onFailure}
        {...props}
      >
        <SimpleForm className={classes.SimpleForm}>
          <Grid style={{ width: "auto" }} container spacing={10}>
            <Grid className={classes.ptb} item xl={4} md={12} sm={12}>
              <TextInput
                fullWidth
                source="name"
                label="Location Name"
                validate={required()}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={4} md={6} sm={12}>
              <TextInput fullWidth source="country" validate={required()} />
            </Grid>

            <Grid className={classes.ptb} item xl={4} md={6} sm={12}>
              <TextInput fullWidth source="address" validate={required()} />
            </Grid>

            <Grid className={classes.ptb} item xl={4} md={6} sm={12}>
              <TextInput fullWidth source="city" validate={required()} />
            </Grid>

            <Grid className={classes.ptb} item xl={4} md={6} sm={12}>
              <TextInput fullWidth source="postal_code" validate={required()} />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
