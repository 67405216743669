import { useState, useContext } from "react";
import {
  Edit,
  useNotify,
  useRedirect,
  useAuthenticated,
  FormWithRedirect,
} from "react-admin";
import { BasicDetails } from "./BasicDetails";
import UsersContext from "../../../context/UsersContext";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};
  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

export const MyProfile = (props) => {
  useAuthenticated();
  const redirect = useRedirect();
  const notify = useNotify();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");
  const { setAllActiveTabs, setShowSideTabs } = useContext(UsersContext);

  const onSuccess = ({ data }) => {
    notify(`Your data has been updated successfully.`, { type: "success" });
    localStorage.setItem("full_name", data.full_name);
    redirect("my-profile");

    if (role === "Client") {
      fetch(`${process.env.REACT_APP_API_URL}/settings/getSetting`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setShowSideTabs(response.data);
          setAllActiveTabs({
            documents: response.data.documents,
            group_chat: response.data.group_chat,
            direct_chat: response.data.direct_chat,
            membership_reporting: response.data.membership_reporting,
            customer_reporting: response.data.customer_reporting,
            staff_management: response.data.staff_management,
          });
        });
    }
  };

  const tabsWithDetails = {
    basic_details: {
      name: "Basic Details",
      component: <BasicDetails {...props} />,
    },
  };

  const tabIds = Object.keys(tabsWithDetails);
  const [currentTabId] = useState(tabIds[0]);
  const classes = useStyles();

  return (
    <Edit
      id="me"
      className={classes.root}
      mutationMode="pessimistic"
      basePath="/my-profile"
      resource="profile"
      redirect={false}
      title="My Profile"
      onSuccess={onSuccess}
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <Container className="myforms" key="content">
            {tabsWithDetails[currentTabId].component}
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
