import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import { UsersProvider } from "./context/UsersContext";
import { MessageProvider } from "./context/MessageContext";
import { ChatProvider } from "./context/ChatUserContext";

ReactDOM.render(
  <React.StrictMode>
    <ChatProvider>
      <MessageProvider>
        <UsersProvider>
          <App />
        </UsersProvider>
      </MessageProvider>
    </ChatProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
