import { useContext, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { MdClose } from "react-icons/md";
import { useAuthenticated, useRefresh, useNotify } from "react-admin";
import UsersContext from "../../../context/UsersContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfileImgModal({ open, setOpen, setLoader }) {
  useAuthenticated();
  const notify = useNotify();
  const refresh = useRefresh();
  const token = localStorage.getItem("auth");
  const { setUserAvatar } = useContext(UsersContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleuploadprofileimage = (event) => {
    const file = event.target.files[0];

    if (file !== undefined && file !== null) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", file);
      setOpen(false);
      setLoader(true);

      fetch(`${process.env.REACT_APP_API_URL}/user/uploadProfilePic`, {
        method: "POST",
        body: bodyFormData,
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          notify(`Profile updated successfully.`, { type: "success" });
          localStorage.setItem("profile_image", response.profile_image);
          setUserAvatar(response.profile_image);
          setLoader(false);
          refresh();
        });
    }
  };

  const handledeleteprofileimage = () => {
    setOpen(false);
    setLoader(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/deleteProfilePic`, {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        notify(`Profile updated successfully.`, { type: "success" });
        localStorage.setItem("profile_image", response.profile_image);
        setUserAvatar(response.profile_image);
        setLoader(false);
        refresh();
      });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      className="profilePicModal addUserModal"
      TransitionComponent={Transition}
    >
      <h2>
        Change profile picture
        <i onClick={() => setOpen(false)}>
          <MdClose size={24} />
        </i>
      </h2>
      <div className="profilepic">
        <div className="d-flex">
          <label htmlFor="file-upload" className="fileuplaod">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.77252 5.82532L11.7725 2.82532M11.7725 2.82532L14.7725 5.82532M11.7725 2.82532L11.7725 14.8253"
                stroke="#09102A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.27264 8.82532L6.77264 8.82532C4.56351 8.82532 2.77264 10.6162 2.77264 12.8253L2.77264 16.8253C2.77264 19.0345 4.56351 20.8253 6.77264 20.8253L16.7726 20.8253C18.9818 20.8253 20.7726 19.0345 20.7726 16.8253L20.7726 12.8253C20.7726 10.6162 18.9818 8.82532 16.7726 8.82532L16.2726 8.82532"
                stroke="#09102A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              id="file-upload"
              type="file"
              accept="image/png, image/jpeg, image/gif, image/heic, .heic"
              onChange={handleuploadprofileimage}
              style={{ opacity: 0 }}
            />
            <h5>Upload photo</h5>
          </label>
        </div>
        <div className="d-flex">
          <label className="fileuplaod" onClick={handledeleteprofileimage}>
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2222 7.3L14.5859 15.6761C14.4434 17.5517 12.8987 19 11.0408 19H6.95922C5.10131 19 3.55663 17.5517 3.41414 15.6761L2.77778 7.3M17 5.5C14.6907 4.36058 11.9455 3.7 9 3.7C6.05453 3.7 3.30926 4.36058 1 5.5M7.22222 3.7V2.8C7.22222 1.80589 8.01816 1 9 1C9.98184 1 10.7778 1.80589 10.7778 2.8V3.7M7.22222 9.1L7.22222 14.5M10.7778 9.1V14.5"
                stroke="#09102A"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
            <h5>Delete photo</h5>
          </label>
        </div>
      </div>
    </Dialog>
  );
}
