import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import Filters from "./Filters";
import Loader from "../../General/views/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { EmptyList } from "../../General/views/EmptyList";
import { ListActions } from "../../General/utils/ListActions";
import sortedIcon from "../../../assets/images/sortedIcon.png";
import { ActivityList } from "../../General/utils/ActivityList";
import exportPngIcon from "../../../assets/images/exportPngIcon.png";

const useStyles = makeStyles({
  sort: {
    "&&:after": {
      content: '""',
      height: "18px",
      width: "18px",
      marginLeft: "4px",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      verticalAlign: "middle",
      background: `url(${sortedIcon})`,
    },
  },
});

const TabbedDatagrid = (props) => {
  const classes = useStyles();
  const listContext = useListContext();
  if (props.loading) {
    return <Loader loader={props.loading} />;
  }

  return (
    <ListContextProvider value={{ ...listContext }}>
      <Datagrid
        {...props}
        optimized
        rowClick={false}
        empty={<EmptyList itemName={"Reports"} {...props} />}
      >
        <TextField source="serial_no" label="No" sortable={false} />
        <TextField
          source="first_name"
          label="First Name"
          headerClassName={classes.sort}
        />
        <TextField
          source="last_name"
          label="Last Name"
          headerClassName={classes.sort}
        />
        <TextField
          source="email"
          label="Email Address"
          headerClassName={classes.sort}
        />
        <DateField
          source="createdAt"
          label="Created At"
          headerClassName={classes.sort}
        />
        <FunctionField
          label="Activity"
          source="status"
          headerClassName={classes.sort}
          render={(record) => (
            <ActivityList record={record} resource={props.resource} />
          )}
        />
        <FunctionField
          label=""
          render={(record) => (
            <ListActions record={record} resource={props.resource} />
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export const LeadsList = (props) => {
  useAuthenticated();

  return (
    <section className="section">
      <div className="main-title">
        <h2>Leads</h2>
        <div className="file-exporter">
          <div>
            <span>Export</span>
            <div>
              <img src={exportPngIcon} alt="" />
            </div>
          </div>
        </div>
      </div>

      <List
        {...props}
        exporter={false}
        title=" "
        filters={<Filters />}
        bulkActionButtons={false}
        filterDefaultValues={{ status: "all" }}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <TabbedDatagrid />
      </List>
    </section>
  );
};
