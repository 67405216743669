import React, { useState, useContext } from "react";
import ProfileImgModal from "./ProfileImgModal";
import {
  TextInput,
  DateField,
  PasswordInput,
  CheckboxGroupInput,
} from "react-admin";
import Loader from "../../General/views/Loader";
import UsersContext from "../../../context/UsersContext";
import { Grid, Typography, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    subHeading: {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "22px",
      marginBottom: "30px",
      color: "#5B6280!important",
      paddingLeft: "15px",
    },
    ptb: {
      paddingTop: "15px!important",
      paddingBottom: "0!important",
      paddingLeft: "15px!important",
      paddingRight: "15px!important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
    inputField: {
      width: "100%",
      borderRadius: "6px",
    },
    freeTrail: {
      fontSize: "15px",
    },
    wrapper: {
      background: "#ffffff",
      borderRadius: "10px",
      padding: "30px",
      boxShadow: "0px 3px 6px rgba(91, 98, 128, 0.1)",
    },
  })
);

export const BasicDetails = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");
  const userAvatar = localStorage.getItem("profile_image");
  const { loader, setLoader } = useContext(UsersContext);

  return (
    <>
      <Loader loader={loader} />

      <div className="main-title">
        <h2>My Profile</h2>
      </div>

      <Grid container className={classes.wrapper}>
        <Grid item xl={3} md={3} sm={3}>
          <div className="profile-image">
            <img
              src={userAvatar}
              alt="Avatar"
              title="Update Profile Picture"
              onClick={() => setOpen(true)}
            />
            <div>
              <h4>{role}</h4>
            </div>
          </div>
        </Grid>

        <Grid item xl={8} md={8} sm={8}>
          <Typography variant="h6" className={classes.subHeading}>
            Account Information
          </Typography>

          <div className="row">
            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <TextInput
                source="full_name"
                label="Full name*"
                className={classes.inputField}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <TextInput
                disabled
                source="username"
                label="Username*"
                className={classes.inputField}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <TextInput
                disabled
                source="email"
                label="Email*"
                className={classes.inputField}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <TextInput
                source="phone"
                label="Phone number*"
                className={classes.inputField}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <TextInput
                source="postal_code"
                label="Post Code*"
                className={classes.inputField}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        key="passwords"
        className={classes.wrapper}
        style={{ margin: "30px 0" }}
      >
        <Grid item xl={3} md={3} sm={3} />

        <Grid item xl={8} md={8} sm={8}>
          <Typography variant="h6" className={classes.subHeading}>
            Update Password
          </Typography>

          <div className="row password-row">
            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <PasswordInput
                source="password"
                inputProps={{ autocomplete: "false" }}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
              <PasswordInput
                source="confirm_password"
                inputProps={{ autocomplete: "false" }}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>

      {role === "Client" && (
        <Grid container>
          <Grid item xl={5} md={5} sm={5} className={classes.wrapper}>
            <Typography
              variant="h6"
              style={{ padding: 0, marginBottom: "15px" }}
              className={classes.subHeading}
            >
              Sidebar Tab Settings
            </Typography>

            <div className={classes.freeTrail}>
              <span>Your free trial will be expired at: </span>
              <DateField source="freeTrialEndDate" />
            </div>

            <CheckboxGroupInput
              source="module_settings"
              label=" "
              className="moduleSettings"
              choices={[
                { id: "documents", name: "Documents" },
                { id: "direct_chat", name: "Direct Chat" },
                { id: "group_chat", name: "Group Chat" },
                { id: "customer_reporting", name: "Customer Reporting" },
                { id: "membership_reporting", name: "MemberShip Reporting" },
              ]}
            />
          </Grid>
        </Grid>
      )}
      <ProfileImgModal open={open} setOpen={setOpen} setLoader={setLoader} />
    </>
  );
};
