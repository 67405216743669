import { useState, useEffect, useContext, forwardRef } from "react";
import {
  AutocompleteArrayInput,
  useAuthenticated,
  SimpleForm,
  useRefresh,
  TextInput,
  useNotify,
  required,
  Create,
  ImageInput,
  ImageField,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { MdClose } from "react-icons/md";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { createStyles, makeStyles } from "@material-ui/core";
import ChatUserContext from "../../../context/ChatUserContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "30px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
  })
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateGroupModal({ modalOpen, setModalOpen, props }) {
  useAuthenticated();
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");
  const [addLocations, setAddLocations] = useState([]);
  const { getGroupNames } = useContext(ChatUserContext);

  useEffect(() => {
    if (!modalOpen) {
      refresh();
    }

    if ((role !== "Super Admin" || role !== "Staff") && modalOpen) {
      fetch(`${process.env.REACT_APP_API_URL}/location/getAllLocations`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setAddLocations(response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const onSuccess = () => {
    notify(`Group created successfully.`, { type: "success" });
    setModalOpen(false);

    fetch(`${process.env.REACT_APP_API_URL}/group/getAllGroups`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        getGroupNames(response);
      });
  };

  const onFailure = () => {
    notify("Something went wrong", { type: "warning" });
  };

  const addLocationChoices = () => {
    if (!addLocations) return [];
    return (addLocations ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Dialog
      open={modalOpen}
      keepMounted
      onClose={handleClose}
      className="addUserModal"
      TransitionComponent={Transition}
    >
      <h2>
        Create New Group
        <i onClick={() => setModalOpen(false)}>
          <MdClose size={28} />
        </i>
      </h2>
      <Create
        className="mybg"
        title=" "
        redirect={false}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onFailure}
        {...props}
      >
        <SimpleForm className={classes.SimpleForm}>
          <Grid style={{ width: "auto" }} container spacing={10}>
            <Grid className={classes.ptb} item xl={4} md={6} sm={12}>
              <TextInput
                fullWidth
                source="title"
                label="Group Name"
                validate={required()}
              />
            </Grid>

            <Grid className={classes.ptb} item xl={4} md={6} sm={12}>
              <TextInput
                fullWidth
                source="description"
                label="Group Description (optional)"
                validate={required()}
              />
            </Grid>

            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={4}
              md={6}
              sm={12}
            >
              <AutocompleteArrayInput
                fullWidth
                validate={required()}
                label="Access Level"
                source="access_level"
                className={classes.inputField}
                choices={[
                  { id: "Owner", name: "Owner" },
                  { id: "Staff", name: "Staff" },
                ]}
              />
            </Grid>

            {role === "Owner" && (
              <Grid
                className={`${classes.ptb} multiSelect`}
                item
                xl={4}
                md={6}
                sm={6}
              >
                {role !== "Super Admin" && (
                  <AutocompleteArrayInput
                    fullWidth
                    label="Locations"
                    source="locations"
                    validate={required()}
                    choices={addLocationChoices()}
                  />
                )}
              </Grid>
            )}

            <Grid
              className={`${classes.ptb} uploadPic`}
              item
              xl={12}
              md={12}
              sm={12}
            >
              <ImageInput
                source="image"
                accept="image/*"
                label="Profile Picture"
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
