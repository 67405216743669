import { useRedirect } from "react-admin";
import { createStyles, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(2),
      cursor: "pointer",
      fontWeight: "bold",
    },
  })
);

export const ViewActions = ({ record, resource }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return [
    <Typography
      color="secondary"
      display="inline"
      key={`button-edit`}
      variant="body1"
      className={classes.root}
      onClick={() => redirect(`/${resource}/${record.id}/show`)}
    >
      View
    </Typography>,
  ];
};
