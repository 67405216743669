import { useState, useEffect, useContext, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Grid } from "@material-ui/core";
import { MdClose } from "react-icons/md";
import { createStyles, makeStyles } from "@material-ui/core";
import ChatUserContext from "../../../context/ChatUserContext";
import {
  AutocompleteArrayInput,
  useAuthenticated,
  SimpleForm,
  useRefresh,
  TextInput,
  useNotify,
  required,
  Create,
} from "react-admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
    SimpleForm: {
      padding: "40px",
    },
    ptb: {
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
  })
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUserModal({ open, setOpen, props }) {
  useAuthenticated();
  const notify = useNotify();
  const classes = useStyles();
  const refresh = useRefresh();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");
  const [addLocations, setAddLocations] = useState([]);
  const { getAllUsers } = useContext(ChatUserContext);

  useEffect(() => {
    if (!open) {
      refresh();
    }
    if (role !== "Super Admin" && open) {
      fetch(`${process.env.REACT_APP_API_URL}/location/getAllLocations`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setAddLocations(response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    setOpen(false);
    notify(`User added successfully.`, { type: "success" });

    if (role !== "Super Admin") {
      fetch(`${process.env.REACT_APP_API_URL}/messages/getUsersList`, {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          getAllUsers(response);
        });
    }
  };

  const onFailure = (data) => {
    notify(data.message, { type: "warning" });
  };

  const showHeading = () => {
    const roleMappings = {
      "Super Admin": "Add New Client",
      Client: "Add New Owner",
      Owner: "Add New Staff",
      Staff: "Add New User",
    };
    return roleMappings[role] ?? "Add New User";
  };

  const addLocationChoices = () => {
    if (!addLocations) return [];
    return (addLocations ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      className="addUserModal"
      TransitionComponent={Transition}
    >
      <h2>
        {showHeading()}
        <i onClick={() => setOpen(false)}>
          <MdClose size={28} />
        </i>
      </h2>
      <Create
        className="mybg"
        title=" "
        redirect={false}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onFailure}
        {...props}
      >
        <SimpleForm className={classes.SimpleForm}>
          <Grid style={{ width: "auto" }} container spacing={10}>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput fullWidth source="full_name" validate={required()} />
            </Grid>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput fullWidth source="username" validate={required()} />
            </Grid>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput fullWidth source="email" validate={required()} />
            </Grid>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput fullWidth source="postal_code" validate={required()} />
            </Grid>
            <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
              <TextInput fullWidth source="phone" validate={required()} />
            </Grid>
            <Grid
              className={`${classes.ptb} multiSelect`}
              item
              xl={4}
              md={6}
              sm={6}
            >
              {role !== "Super Admin" && (
                <AutocompleteArrayInput
                  fullWidth
                  label="Locations"
                  source="locations"
                  validate={required()}
                  choices={addLocationChoices()}
                />
              )}
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
