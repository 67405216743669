import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import Filters from "./Filters";
import { useState, Fragment } from "react";
import { Divider } from "@material-ui/core";
import Loader from "../../General/views/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { EmptyList } from "../../General/views/EmptyList";
import { ListActions } from "../../General/utils/ListActions";
import CreateDocTypeModal from "../Create/CreateDocTypeModal";
import sortedIcon from "../../../assets/images/sortedIcon.png";
import exportPngIcon from "../../../assets/images/exportPngIcon.png";

const useStyles = makeStyles({
  sort: {
    "&&:after": {
      content: '""',
      height: "18px",
      width: "18px",
      marginLeft: "4px",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      verticalAlign: "middle",
      background: `url(${sortedIcon})`,
    },
  },
});

const TabbedDatagrid = (props) => {
  const classes = useStyles();
  const listContext = useListContext();
  if (props.loading) {
    return <Loader loader={props.loading} />;
  }

  return (
    <Fragment>
      <Divider />
      <ListContextProvider value={{ ...listContext }}>
        <Datagrid
          {...props}
          optimized
          rowClick={false}
          empty={<EmptyList itemName={"Reports"} {...props} />}
        >
          <TextField source="serial_no" label="No" sortable={false} />
          <TextField
            source="name"
            label="Document Name"
            headerClassName={classes.sort}
          />
          <TextField
            source="file_type"
            label="Document Type"
            headerClassName={classes.sort}
          />
          <DateField
            source="created_at"
            label="Created At"
            headerClassName={classes.sort}
          />
          <FunctionField
            label=" "
            render={(record) => (
              <ListActions record={record} resource={props.resource} />
            )}
          />
        </Datagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export const DocManagementList = (props) => {
  useAuthenticated();
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");

  return (
    <>
      <section className="section">
        <div className="main-title">
          <h2>Doc Management</h2>
          <div className="file-exporter">
            <div>
              <span>Export</span>
              <div>
                <img src={exportPngIcon} alt="" />
              </div>
            </div>
            {role === "Client" && (
              <button onClick={() => setOpen(true)}>+ Create Type</button>
            )}
          </div>
        </div>
        <CreateDocTypeModal open={open} setOpen={setOpen} props={props} />

        <List
          {...props}
          exporter={false}
          title=" "
          filters={<Filters />}
          bulkActionButtons={false}
          filterDefaultValues={{ status: "all" }}
          sort={{ field: "created_at", order: "DESC" }}
        >
          <TabbedDatagrid />
        </List>
      </section>
    </>
  );
};
