import {
  List,
  Datagrid,
  useNotify,
  TextField,
  DateField,
  useRefresh,
  FunctionField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import { useState } from "react";
import Filters from "./Filters";
import Loader from "../../General/views/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { EmptyList } from "../../General/views/EmptyList";
import { ListActions } from "../../General/utils/ListActions";
import sortedIcon from "../../../assets/images/sortedIcon.png";
import sampleIcon from "../../../assets/images/sampleIcon.png";
import importIcon from "../../../assets/images/importIcon.png";
import AssignLocation from "../../General/utils/AssignLocation";
// import exportPngIcon from "../../../assets/images/exportPngIcon.png";
import {
  getOriginalCsvData,
  getParsedFileData,
} from "../../General/utils/FileUploadGeneral";

const useStyles = makeStyles({
  sort: {
    "&&:after": {
      content: '""',
      height: "18px",
      width: "18px",
      marginLeft: "4px",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      verticalAlign: "middle",
      background: `url(${sortedIcon})`,
    },
  },
});

const TabbedDatagrid = (props) => {
  const classes = useStyles();
  const listContext = useListContext();
  if (props.loading) {
    return <Loader loader={props.loading} />;
  }

  return (
    <ListContextProvider value={{ ...listContext }}>
      <Datagrid
        {...props}
        optimized
        rowClick={false}
        empty={<EmptyList itemName={"Reports"} {...props} />}
      >
        <TextField source="serial_no" label="No" sortable={false} />
        <TextField
          source="name"
          label="Location Name"
          headerClassName={classes.sort}
        />
        <TextField
          source="country"
          label="Country"
          headerClassName={classes.sort}
        />
        <TextField source="city" label="City" headerClassName={classes.sort} />
        <TextField
          source="address"
          label="Address"
          headerClassName={classes.sort}
        />
        <TextField
          source="postal_code"
          label="Postal Code"
          headerClassName={classes.sort}
        />
        <DateField
          source="created_at"
          label="Created At"
          headerClassName={classes.sort}
        />
        <FunctionField
          label=""
          render={(record) => (
            <ListActions record={record} resource={props.resource} />
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export const LocationsList = (props) => {
  useAuthenticated();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");

  const ParseExcel = async (event) => {
    const file = event.target.files[0];
    const originalData = await getOriginalCsvData(file);

    const allIndexes = {
      name: null,
      city: null,
      address: null,
      country: null,
      postal_code: null,
    };
    const essentialindexes = [
      "name",
      "city",
      "address",
      "country",
      "postal_code",
    ];
    const parsedFileData = getParsedFileData(
      originalData,
      allIndexes,
      essentialindexes
    );

    if (parsedFileData) {
      setLoader(true);
      fetch(`${process.env.REACT_APP_API_URL}/location/uploadBulkLocation`, {
        method: "POST",
        body: JSON.stringify({
          locations: parsedFileData,
        }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setTimeout(() => {
            notify(response.message, "success");
          }, 500);
          refresh();
        })
        .catch(() => {
          notify(`Error occurred while uploading`, "warning");
        });
    } else {
      notify("The uploaded file is not correct", { type: "warning" });
    }
    setLoader(false);
    event.target.value = null;
  };

  const handleBulkDelete = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/location/deleteAllLocations`, {
      method: "DELETE",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        notify(response.message, { type: "success" });
        refresh();
      });
  };

  return (
    <>
      <Loader loader={loader} />
      <section className="reporting section">
        <div className="main-title">
          <h2>Location</h2>
          <div className="d-flex">
            <div className="file-exporter">
              {/* <div>
                <span>Export</span>
                <div>
                  <img src={exportPngIcon} alt="" />
                </div>
              </div> */}
              {(role === "Client" || role === "Owner") && (
                <button onClick={() => setOpen(true)}>+ Create Location</button>
              )}
            </div>

            {(role === "Client" || role === "Owner") && (
              <div className="d-flex align-initial">
                <a
                  href="https://luminary-analytics.s3.amazonaws.com/sample-csv/bulk-location-sample-csv.csv"
                  download
                >
                  <img src={sampleIcon} alt="" />
                  <span>Download Sample</span>
                </a>

                <label>
                  <input
                    type="file"
                    name="file-input"
                    id="file-upload"
                    accept=".xlsx,.csv"
                    className="file-input__input"
                    onChange={ParseExcel}
                  />
                  <img src={importIcon} alt="" />
                  <span>Import Data</span>
                </label>

                <label onClick={handleBulkDelete}>
                  <span>Reset Data</span>
                </label>
              </div>
            )}
          </div>
        </div>
        <AssignLocation open={open} setOpen={setOpen} props={props} />

        <List
          {...props}
          exporter={false}
          title=" "
          filters={<Filters />}
          bulkActionButtons={false}
          filterDefaultValues={{ status: "all" }}
          sort={{ field: "created_at", order: "DESC" }}
        >
          <TabbedDatagrid />
        </List>
      </section>
    </>
  );
};
