import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Pie,
  Cell,
  Area,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  PieChart,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  List,
  useNotify,
  DateField,
  TextField,
  useRefresh,
  FunctionField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import moment from "moment";
import Filters from "./Filters";
import { useSelector } from "react-redux";
import Loader from "../../../General/views/Loader";
import { groupBy, mapValues, sortBy } from "lodash";
import CustomizableDatagrid from "ra-customizable-datagrid";
import { EmptyList } from "../../../General/views/EmptyList";
import MessageContext from "../../../../context/MessageContext";
import { ListActions } from "../../../General/utils/ListActions";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import {
  getOriginalCsvData,
  getParsedFileData,
} from "../../../General/utils/FileUploadGeneral";
import { makeStyles } from "@material-ui/core/styles";
import sampleIcon from "../../../../assets/images/sampleIcon.png";
import importIcon from "../../../../assets/images/importIcon.png";
import sortedIcon from "../../../../assets/images/sortedIcon.png";
import exportPngIcon from "../../../../assets/images/exportPngIcon.png";

const useStyles = makeStyles({
  sort: {
    "&&:after": {
      content: '""',
      height: "18px",
      width: "18px",
      marginLeft: "4px",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      verticalAlign: "middle",
      background: `url(${sortedIcon})`,
    },
  },
});

const TabbedDatagrid = (props) => {
  const classes = useStyles();
  const listContext = useListContext();
  const token = localStorage.getItem("auth");
  const columnsData = localStorage.getItem("raColumnsConfig");
  const parseColData = JSON.parse(columnsData);

  const CustomStorage = {
    get: (resourceName) => (resourceName = parseColData?.undefined),

    set: (resourceName, selectedColumns) => {
      fetch(`${process.env.REACT_APP_API_URL}/report/setTableArrangement`, {
        method: "POST",
        body: JSON.stringify({
          reportType: "customer",
          ...selectedColumns,
        }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      });
      let data = JSON.stringify({
        undefined: selectedColumns,
      });
      localStorage.setItem("raColumnsConfig", data);
    },
  };

  return (
    <Fragment>
      <ListContextProvider value={{ ...listContext }}>
        <CustomizableDatagrid
          // {...props}
          // optimized
          storage={CustomStorage}
          empty={<EmptyList itemName={"Customers"} {...props} />}
        >
          <TextField source="serial_no" label="No" sortable={false} />
          <TextField source="first_name" headerClassName={classes.sort} />
          <TextField source="last_name" headerClassName={classes.sort} />
          <TextField source="email" headerClassName={classes.sort} />
          <TextField source="location" headerClassName={classes.sort} />
          <TextField
            source="joining_source"
            label="Source"
            headerClassName={classes.sort}
          />
          <TextField source="phone" headerClassName={classes.sort} />
          <DateField
            label="Date Joining"
            source="joining_date"
            headerClassName={classes.sort}
          />
          <FunctionField
            label=" "
            render={(record) => (
              <ListActions record={record} resource={props.resource} />
            )}
          />
        </CustomizableDatagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export const Customers = (props) => {
  useAuthenticated();
  const notify = useNotify();
  const refresh = useRefresh();
  const token = localStorage.getItem("auth");
  const COLORS = ["#FF5AA9", "#FFAD4E", "#9BE94E", "#8951FF"];
  const { loader, setLoader } = useContext(MessageContext);
  const [graphData, setGraphData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    totalCustomersCount: 0,
    todayCustomerCount: 0,
    customerPercentage: 0,
    thisWeekCustomerCount: 0,
    thisMonthCustomerCount: 0,
    thisYearCustomerCount: 0,
    previousWeekFlag: undefined,
  });
  const reportingData = useSelector(
    (state) => state?.admin?.resources?.customerReporting?.data
  );

  useEffect(() => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/report/reportingData?reporting_type=customer`,
      {
        method: "GET",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setLoader(false);
        generatePieChartData(response);
        generateDashboardData(response);
        generateLineChartData(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, reportingData]);

  const ParseExcel = async (event) => {
    const file = event.target.files[0];
    const originalData = await getOriginalCsvData(file);

    const allIndexes = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      location: null,
      joining_date: null,
      joining_source: null,
    };
    const essentialindexes = [
      "email",
      "location",
      "joining_date",
      "joining_source",
    ];
    const parsedFileData = getParsedFileData(
      originalData,
      allIndexes,
      essentialindexes
    );

    if (parsedFileData) {
      setLoader(true);
      for (let parsedData of parsedFileData) {
        const joiningDate = moment(parsedData.joining_date).format(
          "MM/DD/YYYY"
        );
        parsedData.joining_date = joiningDate;
      }
      fetch(`${process.env.REACT_APP_API_URL}/report/newReport`, {
        method: "POST",
        body: JSON.stringify({
          reports: parsedFileData,
          reporting_type: "customer",
        }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setTimeout(() => {
            notify(response.message, "success");
          }, 500);
          refresh();
        })
        .catch(() => {
          notify(`Error occurred while uploading`, "warning");
        });
    } else {
      notify("The uploaded file is not correct", { type: "warning" });
    }
    setLoader(false);
    event.target.value = null;
  };

  const generatePieChartData = (rawData) => {
    const groupedBySource = mapValues(groupBy(rawData, "joining_source"));
    setGraphData([]);

    for (let source in groupedBySource) {
      const groupArray = groupedBySource[source];
      setGraphData((current) => [
        ...current,
        { name: source, value: groupArray.length },
      ]);
    }
  };

  const generateLineChartData = (rawData) => {
    const sortedArray = sortBy(rawData, ["joining_date"]);
    const groupedByJoinDate = mapValues(groupBy(sortedArray, "joining_date"));
    setLineChartData([]);

    for (let joinDate in groupedByJoinDate) {
      const groupArray = groupedByJoinDate[joinDate];
      setLineChartData((current) => [
        ...current,
        { date: joinDate, customers: groupArray.length },
      ]);
    }
  };

  const generateDashboardData = (rawData) => {
    const today = [];
    const thisMonth = [];
    const thisYear = [];
    const thisWeek = [];
    const previousWeek = [];
    let todayDate = new Date();
    let month = new Date(todayDate).getMonth() + 1;
    let year = new Date(todayDate).getFullYear();
    let date = new Date(todayDate).getDate();
    let weekStartDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate() - (new Date(todayDate).getDay() - 1)
    );
    let previousWeekStartDate = new Date(
      weekStartDate.getFullYear(),
      weekStartDate.getMonth(),
      weekStartDate.getDate() - 6
    );
    let previousWeekEndDate = new Date(
      previousWeekStartDate.getFullYear(),
      previousWeekStartDate.getMonth(),
      previousWeekStartDate.getDate() + 5
    );
    for (let element of rawData) {
      if (
        date === new Date(element.joining_date).getDate() &&
        month === new Date(element.joining_date).getMonth() + 1 &&
        year === new Date(element.joining_date).getFullYear()
      ) {
        today.push(element);
      }
      if (
        month === new Date(element.joining_date).getMonth() + 1 &&
        year === new Date(element.joining_date).getFullYear()
      ) {
        thisMonth.push(element);
      }
      if (year === new Date(element.joining_date).getFullYear()) {
        thisYear.push(element);
      }
      if (
        new Date(element.joining_date) <= todayDate &&
        new Date(element.joining_date) >= weekStartDate
      ) {
        thisWeek.push(element);
      }
      if (
        new Date(element.joining_date) <= previousWeekEndDate &&
        new Date(element.joining_date) >= previousWeekStartDate
      ) {
        previousWeek.push(element);
      }
    }
    let previousWeekPercentage =
      previousWeek.length === 0
        ? 0
        : (thisWeek.length / previousWeek.length) * 100;
    let previousWeekFlag = thisWeek.length > previousWeek.length ? true : false;
    setDashboardData({
      ...dashboardData,
      totalCustomersCount: rawData.length,
      todayCustomerCount: today.length,
      thisWeekCustomerCount: thisWeek.length,
      thisMonthCustomerCount: thisMonth.length,
      thisYearCustomerCount: thisYear.length,
      customerPercentage: previousWeekPercentage,
      previousWeekFlag: previousWeekFlag,
    });
  };

  const handleBulkDelete = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_API_URL}/report/deleteAllReports?reporting_type=customer`,
      {
        method: "DELETE",
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        notify(response.message, { type: "success" });
        let data = JSON.stringify({
          undefined: {
            email: true,
            first_name: true,
            joining_date: true,
            joining_source: true,
            last_name: true,
            location: true,
            phone: true,
            serial_no: 1,
          },
        });
        localStorage.setItem("raColumnsConfig", data);
        refresh();
      });
  };

  return (
    <>
      <Loader loader={loader} />
      <section className="reporting section">
        <div className="main-title" id="react-admin-title">
          <h3>Customers</h3>
          <div className="buttons-wrapper">
            <div className="file-exporter">
              <div>
                <span>Export</span>
                <div>
                  <img src={exportPngIcon} alt="" />
                </div>
              </div>
            </div>

            <div className="d-flex align-initial">
              <a
                href="https://luminary-analytics.s3.amazonaws.com/sample-csv/customers-sample-csv.csv"
                download
              >
                <img src={sampleIcon} alt="" />
                <span>Download Sample</span>
              </a>

              <label>
                <input
                  type="file"
                  name="file-input"
                  id="file-upload"
                  accept=".xlsx,.csv"
                  className="file-input__input"
                  onChange={ParseExcel}
                />
                <img src={importIcon} alt="" />
                <span>Import Data</span>
              </label>

              <label onClick={handleBulkDelete}>
                <span>Reset Data</span>
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row overall-stats">
              <div className="col-md-6">
                <div className="stats-box">
                  <h4>TOTAL CUSTOMERS</h4>
                  <h2>{dashboardData.totalCustomersCount}</h2>
                  <div className="t-customers">
                    {dashboardData.customerPercentage !== 0 &&
                      (dashboardData.previousWeekFlag ? (
                        <i className="increment">
                          <AiOutlineArrowUp />
                        </i>
                      ) : (
                        <i className="decrement">
                          <AiOutlineArrowDown />
                        </i>
                      ))}

                    <p>
                      {dashboardData.customerPercentage.toFixed(2)}% From last
                      week
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 stats">
                <div className="stats-box">
                  <h3>{dashboardData.todayCustomerCount}</h3>
                  <h4>TODAY</h4>
                </div>
                <div className="stats-box">
                  <h3>{dashboardData.thisWeekCustomerCount}</h3>
                  <h4>THIS WEEK</h4>
                </div>
              </div>

              <div className="col-md-3 stats">
                <div className="stats-box">
                  <h3>{dashboardData.thisYearCustomerCount}</h3>
                  <h4>THIS YEAR</h4>
                </div>
                <div className="stats-box">
                  <h3>{dashboardData.thisMonthCustomerCount}</h3>
                  <h4>THIS MONTH</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="chart-container">
              <div className="pie-stats">
                <h2>New Customer Intake</h2>

                {graphData.length > 0 ? (
                  <PieChart
                    width={450}
                    height={200}
                    style={{ marginTop: "-10px" }}
                  >
                    <Pie
                      data={graphData}
                      cx={200}
                      cy={100}
                      innerRadius={60}
                      outerRadius={160}
                      fill="#8884d8"
                      paddingAngle={0}
                      dataKey="value"
                      key={Math.random()}
                    >
                      {graphData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      iconType="square"
                      width={500}
                      iconSize={18}
                    />
                    <Tooltip
                      offset={0}
                      itemStyle={{
                        backGroung: "red",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    />
                  </PieChart>
                ) : (
                  <span className="pieNotFound notFound">No Data Found...</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="reporting-detail">
          <div className="chart-wrapper">
            <h2>New Customers</h2>
            {lineChartData.length > 0 && (
              <ResponsiveContainer width="98%" aspect={3}>
                <AreaChart
                  width={730}
                  height={250}
                  data={lineChartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#01D1D1" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#01D1D1" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <CartesianGrid
                    horizontal="true"
                    vertical=""
                    strokeDasharray="10"
                    opacity={0.7}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="customers"
                    stroke="#01D1D1"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>

          <List
            {...props}
            exporter={false}
            bulkActionButtons={false}
            title=" "
            filters={<Filters />}
            filterDefaultValues={{ status: "customers" }}
            sort={{ field: "created_at", order: "DESC" }}
          >
            <TabbedDatagrid />
          </List>
        </div>
      </section>
    </>
  );
};
