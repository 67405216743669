import { Attachments } from "./Attachments";
import { useAuthenticated, Edit, TabbedForm } from "react-admin";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";

export const DocumentsEdit = (props) => {
  useAuthenticated();

  return (
    <section className="section">
      <Edit title="Edit Document" mutationMode="pessimistic" {...props}>
        <TabbedForm
          toolbar={<EditBottomToolBar key="toolbar" {...props} />}
          redirect="list"
        >
          <Attachments />
        </TabbedForm>
      </Edit>
    </section>
  );
};
