import { useEffect, useState } from "react";
import { TextInput, SelectInput, AutocompleteArrayInput } from "react-admin";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    subHeading: {
      fontWeight: 500,
      fontSize: "22px",
      marginBottom: "30px",
      color: "#5B6280!important",
      paddingLeft: "15px",
    },
    ptb: {
      paddingTop: "15px!important",
      paddingBottom: "0!important",
      paddingLeft: "15px!important",
      paddingRight: "15px!important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
    inputField: {
      width: "100%",
      borderRadius: "6px",
    },
  })
);

export const BasicDetails = (props) => {
  const classes = useStyles();
  const token = localStorage.getItem("auth");
  const [addLocations, setAddLocations] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/location/getAllLocations`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setAddLocations(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addLocationChoices = () => {
    if (!addLocations) return [];
    return (addLocations ?? []).map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
      };
    });
  };

  return (
    <Box component="section" className={`${classes.root} myforms`}>
      <Grid style={{ width: "100%" }} container>
        <Typography variant="h6" className={classes.subHeading}>
          Edit Vendor Details
        </Typography>

        <div className="row">
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              fullWidth
              source="full_name"
              className={classes.inputField}
            />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              fullWidth
              source="username"
              className={classes.inputField}
            />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              fullWidth
              source="email"
              className={classes.inputField}
            />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              fullWidth
              source="postal_code"
              className={classes.inputField}
            />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <TextInput
              fullWidth
              source="phone"
              className={classes.inputField}
            />
          </Grid>
          <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
            <SelectInput
              fullWidth
              source="status"
              className={classes.inputField}
              choices={[
                { id: "Active", name: "Active" },
                { id: "Block", name: "Block" },
              ]}
            />
          </Grid>
          <Grid
            className={`${classes.ptb} multiSelect`}
            item
            xl={4}
            md={6}
            sm={6}
          >
            <AutocompleteArrayInput
              fullWidth
              label="Locations"
              source="locations"
              choices={addLocationChoices()}
              className={classes.inputField}
            />
          </Grid>
        </div>
      </Grid>

      <Grid
        style={{ width: "100%", paddingBottom: "50px" }}
        container
        spacing={10}
      >
        <Grid
          style={{ width: "100%", paddingBottom: "50px" }}
          className={classes.ptb}
          item
          xl={12}
          md={12}
          sm={12}
        ></Grid>
      </Grid>
    </Box>
  );
};
