import { DocumentsList } from "./List/List";
import { DocumentsEdit } from "./Edit/Index";
import { DocumentsShow } from "./Show/Index";

const DocumentsResource = {
  List: DocumentsList,
  Edit: DocumentsEdit,
  Show: DocumentsShow,
};

export default DocumentsResource;
