import { Customers } from "./Customers/List";
import { MemberShip } from "./MemberShip/List";
import { CustomersEdit } from "./Customers/Edit/Index";
import { MemberShipEdit } from "./MemberShip/Edit/Index";

export const CustomerResource = {
  List: Customers,
  Edit: CustomersEdit,
};

export const MemberShipResource = {
  List: MemberShip,
  Edit: MemberShipEdit,
};
