import * as React from "react";
import { Avatar } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      float: "left",
      marginRight: theme.spacing(1),
    },
  })
);

const AvatarWithName = (props) => {
  const classes = useStyles();
  const record = props?.record ?? {};
  const fullName = record?.full_name ?? {};

  return (
    <div className="avatar-name">
      <Avatar
        key={`${fullName.id}-avatar`}
        className={classes.avatar}
        src={record?.profile_image}
        alt="Avatar"
      />
      <span>{fullName}</span>
    </div>
  );
};

export default AvatarWithName;
