import {
  Edit,
  TextInput,
  TopToolbar,
  required,
  useNotify,
  PasswordInput,
  FormWithRedirect,
  useAuthenticated,
} from "react-admin";
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { StatusButtons } from "../../General/views/StatusButtons";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

const PostEditActions = (props) => (
  <TopToolbar>
    <StatusButtons {...props} />
  </TopToolbar>
);

export const EditAdmin = (props) => {
  useAuthenticated();
  const notify = useNotify();

  const onSuccess = ({ data }) => {
    notify(`Your data has been updated successfully.`, { type: "success" });
  };

  const classes = useStyles();
  return (
    <Edit
      id="me"
      className={classes.root}
      mutationMode="pessimistic"
      basePath="/my-profile"
      resource="profile"
      redirect={false}
      title="My Profile"
      onSuccess={onSuccess}
      actions={<PostEditActions />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <Container key="content" className="myforms">
            <Box component="section" className={`${classes.root} myforms`}>
              <Grid
                style={{ width: "100%", paddingBottom: "50px" }}
                container
                spacing={10}
              >
                <Grid
                  style={{ paddingBottom: "0px" }}
                  item
                  xl={12}
                  md={12}
                  sm={12}
                >
                  <Typography variant="h6" className={classes.colorTextgrey}>
                    Account Information
                  </Typography>
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="first_name" validate={required()} />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="last_name" validate={required()} />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="email" validate={required()} />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <TextInput source="phone" />
                </Grid>
              </Grid>

              <Grid
                key="passwords"
                style={{ width: "100%", paddingBottom: "50px" }}
                container
                spacing={10}
              >
                <Grid
                  style={{ paddingBottom: "0px" }}
                  item
                  xl={12}
                  md={12}
                  sm={12}
                >
                  <Typography variant="h6" className={classes.colorTextgrey}>
                    Update Password
                  </Typography>
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <PasswordInput
                    inputProps={{ autocomplete: "false" }}
                    source="password"
                  />
                </Grid>

                <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
                  <PasswordInput
                    inputProps={{ autocomplete: "false" }}
                    source="confirm_password"
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>,

          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
