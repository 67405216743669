import { createContext, useState } from "react";
import moment from "moment";

const MessageContext = createContext();

export function MessageProvider({ children }) {
  const [messages, setMessages] = useState([]);
  const [socketMsg, setSocketMsg] = useState();
  const [deleteMsg, setDeleteMsg] = useState();
  const [editMessage, setEditMessage] = useState();
  const [singleSocketMsg, setSingleSocketMsg] = useState();
  const [loader, setLoader] = useState(true);

  const getAllMessages = (data) => {
    if (Array.isArray(data)) {
      for (let elem of data) {
        const newMainDate = moment(elem.createdAt).format("MM/DD/YYYY");
        if (newMainDate !== "Invalid date") {
          elem.createdAt = newMainDate;
        }
      }
    }
    setMessages(data);
  };

  return (
    <MessageContext.Provider
      value={{
        messages,
        getAllMessages,
        setSocketMsg,
        socketMsg,
        setSingleSocketMsg,
        singleSocketMsg,
        setDeleteMsg,
        deleteMsg,
        setEditMessage,
        editMessage,
        setLoader,
        loader,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}

export default MessageContext;
