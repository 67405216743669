import React, { useState, useContext } from "react";
import moment from "moment";
import ChatUserContext from "../../../context/ChatUserContext";

const GroupChatSidebar = ({ setModalOpen, setSelectedUserId }) => {
  const { groupNames, getGroupNames } = useContext(ChatUserContext);
  const [searchTerm, setSearchTerm] = useState("");

  const resetGroupMsgCount = (id) => {
    for (let group of groupNames) {
      if (group.id === id) {
        group.unread_message_count = 0;
      }
    }
    getGroupNames(groupNames);
  };

  return (
    <div className="chat-header">
      <div className="header-text">
        <h1>Group Chats</h1>
        <button onClick={() => setModalOpen(true)}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.447715 7 1V5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H7V11C7 11.5523 6.55228 12 6 12C5.44772 12 5 11.5523 5 11V7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H5V1C5 0.447715 5.44772 0 6 0Z"
              fill="#09102A"
            />
          </svg>
        </button>
      </div>

      <div className="search-field">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 17L14.6 14.6M1 7.65001C1 3.97731 3.97731 1 7.65 1C11.3227 1 14.3 3.97731 14.3 7.65001C14.3 11.3227 11.3227 14.3 7.65 14.3C3.97731 14.3 1 11.3227 1 7.65001Z"
            stroke="#5B6280"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search message"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="owner-list">
        {groupNames
          .filter((val) => {
            if (searchTerm === "") {
              return val;
            }
            return val.title.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .map((elem, index) => {
            return (
              <div
                key={index}
                className="owners-list-tabs"
                onClick={() => {
                  setSelectedUserId(elem?.id);
                  resetGroupMsgCount(elem?.id);
                }}
              >
                <img src={elem?.group_icon} alt="Avatar" />
                <div className="owners-list-text">
                  <h2>{elem?.title}</h2>
                  <p>{elem?.last_message}</p>
                </div>
                <div className="owners-list-badge">
                  <p>{moment(elem?.createdAt).format("hh:mm A")}</p>
                  {elem?.unread_message_count > 0 && (
                    <p className="badge">{elem?.unread_message_count}</p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GroupChatSidebar;
