import {
  List,
  Datagrid,
  useNotify,
  DateField,
  TextField,
  useRefresh,
  FunctionField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import { useState } from "react";
import Filters from "./Filters";
import Loader from "../../General/views/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { EmptyList } from "../../General/views/EmptyList";
import AddUserModal from "../../General/utils/AddUserModal";
import { ListActions } from "../../General/utils/ListActions";
import sampleIcon from "../../../assets/images/sampleIcon.png";
import importIcon from "../../../assets/images/importIcon.png";
import sortedIcon from "../../../assets/images/sortedIcon.png";
import { ActivityList } from "../../General/utils/ActivityList";
import AvatarWithName from "../../General/views/AvatarWithName";
import {
  getOriginalCsvData,
  getParsedFileData,
} from "../../General/utils/FileUploadGeneral";
// import exportPngIcon from "../../../assets/images/exportPngIcon.png";

const useStyles = makeStyles({
  sort: {
    "&&:after": {
      content: '""',
      height: "18px",
      width: "18px",
      marginLeft: "4px",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      verticalAlign: "middle",
      background: `url(${sortedIcon})`,
    },
  },
});

const TabbedDatagrid = (props) => {
  const classes = useStyles();
  const listContext = useListContext();
  if (props.loading) {
    return <Loader loader={props.loading} />;
  }

  return (
    <ListContextProvider value={{ ...listContext }}>
      <Datagrid
        {...props}
        optimized
        empty={<EmptyList itemName={"Customers"} {...props} />}
      >
        <TextField source="serial_no" label="No" sortable={false} />
        <FunctionField
          source="full_name"
          label="Full name"
          headerClassName={classes.sort}
          render={(record) => (
            <AvatarWithName stopPropagation record={record} />
          )}
        />
        <TextField source="email" headerClassName={classes.sort} />
        <TextField
          source="username"
          label="User Name"
          headerClassName={classes.sort}
        />
        <TextField
          source="postal_code"
          label="Postal Code"
          headerClassName={classes.sort}
        />
        <TextField
          source="phone"
          label="Contact No"
          headerClassName={classes.sort}
        />
        <DateField
          label="Joined on"
          source="created_at"
          headerClassName={classes.sort}
        />
        <FunctionField
          label="Activity"
          source="status"
          headerClassName={classes.sort}
          render={(record) => (
            <ActivityList record={record} resource={props.resource} />
          )}
        />
        <FunctionField
          label=""
          render={(record) => (
            <ListActions record={record} resource={props.resource} />
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export const ClientsList = (props) => {
  useAuthenticated();
  const notify = useNotify();
  const refresh = useRefresh();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("auth");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const ParseExcel = async (event) => {
    const file = event.target.files[0];
    const originalData = await getOriginalCsvData(file);

    const allIndexes = {
      full_name: null,
      username: null,
      email: null,
      postal_code: null,
      phone: null,
    };
    const essentialindexes = [
      "full_name",
      "username",
      "email",
      "postal_code",
      "phone",
    ];
    const parsedFileData = getParsedFileData(
      originalData,
      allIndexes,
      essentialindexes
    );

    if (parsedFileData) {
      setLoader(true);
      fetch(`${process.env.REACT_APP_API_URL}/user/addBulkUser`, {
        method: "POST",
        body: JSON.stringify({
          users: parsedFileData,
        }),
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setTimeout(() => {
            notify(response.message, "success");
          }, 500);
          refresh();
        })
        .catch(() => {
          notify(`Error occurred while uploading`, "warning");
        });
    } else {
      notify("The uploaded file is not correct", { type: "warning" });
    }
    setLoader(false);
    event.target.value = null;
  };

  return (
    <>
      <Loader loader={loader} />
      <section className="section">
        <div className="main-title">
          <h2>Clients</h2>
          <div className="d-flex">
            <div className="file-exporter">
              {/* <div>
            <span>Export</span>
            <div>
              <img src={exportPngIcon} alt="" />
            </div>
          </div> */}
              {role === "Super Admin" && (
                <button onClick={() => setOpen(true)}>+ Add Client</button>
              )}
            </div>

            {role === "Super Admin" && (
              <div className="d-flex align-initial">
                <a
                  href="https://luminary-analytics.s3.amazonaws.com/sample-csv/bulk-clients-sample-csv.csv"
                  download
                >
                  <img src={sampleIcon} alt="" />
                  <span>Download Sample</span>
                </a>

                <label className="ml-0">
                  <input
                    type="file"
                    name="file-input"
                    id="file-upload"
                    accept=".xlsx,.csv"
                    className="file-input__input"
                    onChange={ParseExcel}
                  />
                  <img src={importIcon} alt="" />
                  <span>Import Data</span>
                </label>
              </div>
            )}
          </div>
        </div>
        <AddUserModal open={open} setOpen={setOpen} props={props} />

        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          title=" "
          filters={<Filters />}
          filterDefaultValues={{ status: "all" }}
          sort={{ field: "created_at", order: "DESC" }}
        >
          <TabbedDatagrid />
        </List>
      </section>
    </>
  );
};
